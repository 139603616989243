import { AfterContentInit, Component, HostBinding, HostListener, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ServicesStartupService } from './services/services-startup/services-startup.service';
import { EnvironmentConfigService } from './services/environment-config-service/environment-config.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterContentInit, OnInit {
  @HostBinding('style.--app-width')
  appWidth: number = 0;

  @HostBinding('style.--ion-tab-spacing')
  marginLeft: string = '';

  @HostBinding('style.--app-height')
  appHeight: number = 0;

  @HostBinding('class.landscape')
  landscape = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.appWidth = document.getElementsByTagName('ion-app')[0].clientWidth;
    this.appHeight = document.getElementsByTagName('ion-app')[0].clientHeight;
    const vw = (window.innerWidth - 160 - 150 - ((window.innerWidth - 160 - 150) / 6) * 4.5) / 5;
    this.marginLeft = `${vw}px`;
  }

  private initialized = false;
  public showMenu = true;

  constructor(
    private router: Router,
    public servicesStartupService: ServicesStartupService,
    public navCtrl: NavController,
    private translate: TranslateService,
    private _EnvironmentConfigService: EnvironmentConfigService
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    router.events.forEach(async (event: any) => {
      //After Navigation

      if (event instanceof NavigationEnd) {
        if (event.url) {
          if (event.url.toLowerCase().startsWith('/healthFindr'.toLowerCase())) {
            this.showMenu = false;
          } else {
            this.showMenu = true;
          }
        }
      }
    });
  }

  ngAfterContentInit(): void {
    this.onResize();
  }

  async ngOnInit(): Promise<void> {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    if (!this.initialized) {
      await this.servicesStartupService.initializeAsync(true);
      this.initialized = true;
    }
  }
}
