/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BookingStrictHttpResponse } from '../../booking-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BookingDetailModel } from '../../models/booking-detail-model';

export interface HealthFindrConfirmBookingPost$Params {
      body?: BookingDetailModel
}

export function healthFindrConfirmBookingPost(http: HttpClient, rootUrl: string, params?: HealthFindrConfirmBookingPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, healthFindrConfirmBookingPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as BookingStrictHttpResponse<string>;
    })
  );
}

healthFindrConfirmBookingPost.PATH = '/HealthFindr/ConfirmBooking';
