import { Injectable } from '@angular/core';
import { IonicSafeString, LoadingController, LoadingOptions } from '@ionic/angular';

/**
 * Interface for a loading request.
 */
interface LoadingRequest {
  /**
   * The identifier of the loading request.
   */
  identifier: string;

  /**
   * Optional - loading options to override the default options.
   */
  options?: LoadingOptions;
}

/**
 * Service for managing loading state and displaying loading indicators.
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  /**
   * A stack of loading requests.
   */
  private loadingStack: LoadingRequest[] = [];

  /**
   * The current loading element.
   */
  private currentLoader: HTMLIonLoadingElement | null = null;

  /**
   * A set of identifiers for silent loading requests. These requests will not display a loading indicator.
   */
  private silentIdentifiers: Set<string> = new Set();

  /**
   * The default loading options.
   */
  private defaultLoader: LoadingOptions = {
    spinner: 'bubbles',
    message: 'Loading...',
    showBackdrop: true,
    backdropDismiss: false,
    animated: true,
  };

  /**
   * Whether the service is currently locked. This prevents multiple loading requests from being displayed at once.
   */
  private isLocked = false;

  constructor(private loadingController: LoadingController) {}

  /**
   * Adds a loading request to the loading stack and displays the loading indicator.
   * @param identifier The identifier of the loading request.
   * @param options Optional - loading options to override the default options.
   */
  async addLoadingRequest(identifier: string, options?: LoadingOptions) {
    var checkIdentifier = identifier;
    if (identifier.startsWith('http')) {
      checkIdentifier = await this.getLastUrlSegment(identifier);
    }

    if (this.silentIdentifiers.has(checkIdentifier)) {
      return;
    }

    await this.acquireLock();

    try {
      const existingRequest = this.loadingStack.find((request) => request.identifier === identifier);

      //Removed the API loading messages, too many issue translating and they dont have spaces in between words
      // if (existingRequest) {
      //   // Update the message if a loader with this identifier already exists
      //   if (options && options.message) {
      //     await this.displayLoadingMessage(options.message);
      //   }
      //   return;
      // }

      if (this.currentLoader) {
        await this.currentLoader.dismiss();
        this.currentLoader = null;
      }

      const mergedOptions = { ...this.defaultLoader, ...options };

      // if (!options || !options.message) {
      //   mergedOptions.message = identifier.length <= 16 ? identifier : this.defaultLoader.message;
      // }

      const loadingRequest: LoadingRequest = { identifier, options: mergedOptions };
      this.loadingStack.push(loadingRequest);

      this.currentLoader = await this.loadingController.create(mergedOptions);
      await this.currentLoader.present();
    } finally {
      this.releaseLock();
    }
  }

  /**
   * Dismisses a loading request and removes it from the loading stack.
   * @param identifier The identifier of the loading request to dismiss.
   */
  async dismissLoadingRequest(identifier: string) {
    if (this.silentIdentifiers.has(identifier)) {
      return;
    }

    await this.acquireLock();

    try {
      const index = this.loadingStack.findIndex((request) => request.identifier === identifier);
      if (index !== -1) {
        this.loadingStack.splice(index, 1);

        if (index === this.loadingStack.length) {
          if (this.currentLoader) {
            await this.currentLoader.dismiss();
            this.currentLoader = null;
          }

          if (this.loadingStack.length > 0) {
            const nextRequest = this.loadingStack[this.loadingStack.length - 1];
            this.currentLoader = await this.loadingController.create(nextRequest.options);
            await this.currentLoader.present();
          }
        }
      } else {
        // For debugging purposes
        // console.warn(`No loading request found with identifier: ${identifier}`);
      }
    } finally {
      this.releaseLock();
    }
  }

  /**
   * Closes all active loading indicators and clears the loading stack.
   */
  async closeAllLoaders() {
    if (this.currentLoader) {
      await this.currentLoader.dismiss();
      this.currentLoader = null;
    }
    for (const loader of this.loadingStack) {
      await this.loadingController.dismiss(loader.identifier);
    }

    this.loadingStack = [];
  }

  /**
   * Sets the loading state based on the provided URL.
   * @param loading Whether to set the loading state to true or false.
   * @param url The URL used to derive the loading identifier.
   */
  async setLoading(loading: boolean, url: string): Promise<void> {
    if (loading) {
      await this.addLoadingRequest(this.getLastUrlSegment(url));
    } else {
      await this.dismissLoadingRequest(this.getLastUrlSegment(url));
    }
  }

  /**
   * Registers an identifier as a silent loading request.
   * @param identifier The identifier to register as silent.
   */
  registerSilentIdentifier(identifier: string) {
    this.silentIdentifiers.add(identifier);
  }

  /**
   * Registers a URL as a silent loading request.
   * @param url The URL to register as silent.
   */
  registerSilentUrl(url: string) {
    this.registerSilentIdentifier(this.getLastUrlSegment(url));
  }

  /**
   * Unregisters a previously registered silent identifier.
   * @param identifier The identifier to unregister.
   */
  unregisterSilentIdentifier(identifier: string) {
    this.silentIdentifiers.delete(identifier);
  }

  /**
   * Checks if a URL is registered as a silent loading request.
   * @param url The URL to check.
   * @returns True if the URL is a silent loading request, false otherwise.
   */
  isSilentUrl(url: string): boolean {
    return this.silentIdentifiers.has(this.getLastUrlSegment(url));
  }

  /**
   * Extracts the last segment of a URL.
   * @param url The URL to extract the last segment from.
   * @returns The last segment of the URL.
   */
  getLastUrlSegment(url: string): string {
    if (!url) return url;
    if (!url.includes('/')) return url;

    if (this.isValidUrl(url)) {
      try {
        const urlObject = new URL(url);
        const pathSegments = urlObject.pathname.split('/').filter(Boolean);
        let lastSegment = pathSegments.pop() || '';

        if (this.isUUID(lastSegment) && pathSegments.length > 0) {
          lastSegment = pathSegments[pathSegments.length - 1];
        }

        return lastSegment;
      } catch (error) {
        // For debugging purposes
        // console.error('Error parsing URL:', error);
      }
    }

    // Fallback for relative paths or invalid URLs
    const segments = url.split('/').filter(Boolean);
    return segments.pop() || url;
  }

  /**
   * Checks if a string is a valid URL.
   * @param url The string to check.
   * @returns True if the string is a valid URL, false otherwise.
   */
  private isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Checks if a string is a valid UUID.
   * @param uuid The string to check.
   * @returns True if the string is a valid UUID, false otherwise.
   */
  isUUID(uuid: string): boolean {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(uuid);
  }

  /**
   * Acquires a lock by checking if the lock is already held.
   * If the lock is already held, it waits until the lock is released.
   * Once the lock is acquired, it sets the `isLocked` flag to `true`.
   *
   * @return {Promise<void>} A promise that resolves when the lock is acquired.
   */
  private async acquireLock(): Promise<void> {
    if (this.isLocked) {
      await new Promise<void>((resolve) => {
        const checkLock = () => {
          if (!this.isLocked) {
            resolve();
          } else {
            setTimeout(checkLock, 50);
          }
        };
        checkLock();
      });
    }
    this.isLocked = true;
  }

  /**
   * Releases the lock.
   *
   */
  private releaseLock(): void {
    this.isLocked = false;
  }
}
