/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { BookingDetailModel } from '../models/booking-detail-model';
import { HealthCareServiceResponse } from '../models/health-care-service-response';
import { healthFindrAvailibilityPost } from '../fn/health-findr/health-findr-availibility-post';
import { HealthFindrAvailibilityPost$Params } from '../fn/health-findr/health-findr-availibility-post';
import { healthFindrBookingDetailUrnBookingIdGet } from '../fn/health-findr/health-findr-booking-detail-urn-booking-id-get';
import { HealthFindrBookingDetailUrnBookingIdGet$Params } from '../fn/health-findr/health-findr-booking-detail-urn-booking-id-get';
import { healthFindrCancelBookingPost } from '../fn/health-findr/health-findr-cancel-booking-post';
import { HealthFindrCancelBookingPost$Params } from '../fn/health-findr/health-findr-cancel-booking-post';
import { healthFindrConfirmBookingPost } from '../fn/health-findr/health-findr-confirm-booking-post';
import { HealthFindrConfirmBookingPost$Params } from '../fn/health-findr/health-findr-confirm-booking-post';
import { healthFindrConfirmReservationPost } from '../fn/health-findr/health-findr-confirm-reservation-post';
import { HealthFindrConfirmReservationPost$Params } from '../fn/health-findr/health-findr-confirm-reservation-post';
import { healthFindrFhirIdSpecialitiesGet } from '../fn/health-findr/health-findr-fhir-id-specialities-get';
import { HealthFindrFhirIdSpecialitiesGet$Params } from '../fn/health-findr/health-findr-fhir-id-specialities-get';
import { healthFindrHealthCareServicesPost } from '../fn/health-findr/health-findr-health-care-services-post';
import { HealthFindrHealthCareServicesPost$Params } from '../fn/health-findr/health-findr-health-care-services-post';
import { healthFindrRequestReservationPost } from '../fn/health-findr/health-findr-request-reservation-post';
import { HealthFindrRequestReservationPost$Params } from '../fn/health-findr/health-findr-request-reservation-post';
import { healthFindrRequestSessionGet } from '../fn/health-findr/health-findr-request-session-get';
import { HealthFindrRequestSessionGet$Params } from '../fn/health-findr/health-findr-request-session-get';
import { healthFindrRequestSessionPost } from '../fn/health-findr/health-findr-request-session-post';
import { HealthFindrRequestSessionPost$Params } from '../fn/health-findr/health-findr-request-session-post';
import { healthFindrSessionDetailSessionIdGet } from '../fn/health-findr/health-findr-session-detail-session-id-get';
import { HealthFindrSessionDetailSessionIdGet$Params } from '../fn/health-findr/health-findr-session-detail-session-id-get';
import { SessionDetailRespone } from '../models/session-detail-respone';
import { SessionResponseModel } from '../models/session-response-model';
import { SpecialitySearchResultModel } from '../models/speciality-search-result-model';
import { UrnAvailabilityDayModelAvailibilityModel } from '../models/urn-availability-day-model-availibility-model';

@Injectable({ providedIn: 'root' })
export class CalendarHealthFindrService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `healthFindrFhirIdSpecialitiesGet()` */
  static readonly HealthFindrFhirIdSpecialitiesGetPath = '/HealthFindr/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrFhirIdSpecialitiesGet$Response(params: HealthFindrFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<SpecialitySearchResultModel>> {
    return healthFindrFhirIdSpecialitiesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrFhirIdSpecialitiesGet(params: HealthFindrFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<SpecialitySearchResultModel> {
    return this.healthFindrFhirIdSpecialitiesGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<SpecialitySearchResultModel>): SpecialitySearchResultModel => r.body)
    );
  }

  /** Path part for operation `healthFindrHealthCareServicesPost()` */
  static readonly HealthFindrHealthCareServicesPostPath = '/HealthFindr/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesPost$Response(params?: HealthFindrHealthCareServicesPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<Array<HealthCareServiceResponse>>> {
    return healthFindrHealthCareServicesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesPost(params?: HealthFindrHealthCareServicesPost$Params, context?: HttpContext): Observable<Array<HealthCareServiceResponse>> {
    return this.healthFindrHealthCareServicesPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<Array<HealthCareServiceResponse>>): Array<HealthCareServiceResponse> => r.body)
    );
  }

  /** Path part for operation `healthFindrAvailibilityPost()` */
  static readonly HealthFindrAvailibilityPostPath = '/HealthFindr/Availibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrAvailibilityPost$Response(params?: HealthFindrAvailibilityPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>> {
    return healthFindrAvailibilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrAvailibilityPost(params?: HealthFindrAvailibilityPost$Params, context?: HttpContext): Observable<UrnAvailabilityDayModelAvailibilityModel> {
    return this.healthFindrAvailibilityPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>): UrnAvailabilityDayModelAvailibilityModel => r.body)
    );
  }

  /** Path part for operation `healthFindrRequestReservationPost()` */
  static readonly HealthFindrRequestReservationPostPath = '/HealthFindr/RequestReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestReservationPost$Response(params?: HealthFindrRequestReservationPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return healthFindrRequestReservationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestReservationPost(params?: HealthFindrRequestReservationPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrRequestReservationPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrConfirmReservationPost()` */
  static readonly HealthFindrConfirmReservationPostPath = '/HealthFindr/ConfirmReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrConfirmReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmReservationPost$Response(params?: HealthFindrConfirmReservationPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return healthFindrConfirmReservationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrConfirmReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmReservationPost(params?: HealthFindrConfirmReservationPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrConfirmReservationPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrConfirmBookingPost()` */
  static readonly HealthFindrConfirmBookingPostPath = '/HealthFindr/ConfirmBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrConfirmBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmBookingPost$Response(params?: HealthFindrConfirmBookingPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return healthFindrConfirmBookingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrConfirmBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmBookingPost(params?: HealthFindrConfirmBookingPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrConfirmBookingPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrBookingDetailUrnBookingIdGet()` */
  static readonly HealthFindrBookingDetailUrnBookingIdGetPath = '/HealthFindr/BookingDetail/{urnBookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrBookingDetailUrnBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrBookingDetailUrnBookingIdGet$Response(params: HealthFindrBookingDetailUrnBookingIdGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<BookingDetailModel>> {
    return healthFindrBookingDetailUrnBookingIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrBookingDetailUrnBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrBookingDetailUrnBookingIdGet(params: HealthFindrBookingDetailUrnBookingIdGet$Params, context?: HttpContext): Observable<BookingDetailModel> {
    return this.healthFindrBookingDetailUrnBookingIdGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<BookingDetailModel>): BookingDetailModel => r.body)
    );
  }

  /** Path part for operation `healthFindrCancelBookingPost()` */
  static readonly HealthFindrCancelBookingPostPath = '/HealthFindr/CancelBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrCancelBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrCancelBookingPost$Response(params?: HealthFindrCancelBookingPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return healthFindrCancelBookingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrCancelBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrCancelBookingPost(params?: HealthFindrCancelBookingPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrCancelBookingPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrRequestSessionGet()` */
  static readonly HealthFindrRequestSessionGetPath = '/HealthFindr/RequestSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestSessionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrRequestSessionGet$Response(params?: HealthFindrRequestSessionGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<void>> {
    return healthFindrRequestSessionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestSessionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrRequestSessionGet(params?: HealthFindrRequestSessionGet$Params, context?: HttpContext): Observable<void> {
    return this.healthFindrRequestSessionGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `healthFindrRequestSessionPost()` */
  static readonly HealthFindrRequestSessionPostPath = '/HealthFindr/RequestSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestSessionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestSessionPost$Response(params?: HealthFindrRequestSessionPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<SessionResponseModel>> {
    return healthFindrRequestSessionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestSessionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestSessionPost(params?: HealthFindrRequestSessionPost$Params, context?: HttpContext): Observable<SessionResponseModel> {
    return this.healthFindrRequestSessionPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<SessionResponseModel>): SessionResponseModel => r.body)
    );
  }

  /** Path part for operation `healthFindrSessionDetailSessionIdGet()` */
  static readonly HealthFindrSessionDetailSessionIdGetPath = '/HealthFindr/SessionDetail/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrSessionDetailSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrSessionDetailSessionIdGet$Response(params: HealthFindrSessionDetailSessionIdGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<SessionDetailRespone>> {
    return healthFindrSessionDetailSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrSessionDetailSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrSessionDetailSessionIdGet(params: HealthFindrSessionDetailSessionIdGet$Params, context?: HttpContext): Observable<SessionDetailRespone> {
    return this.healthFindrSessionDetailSessionIdGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<SessionDetailRespone>): SessionDetailRespone => r.body)
    );
  }

}
