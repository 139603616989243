export const variables = {
  appId: '48649115-6eb0-446c-99e7-5a2c479e86ff',
  scope: 'https://alg-uat-sa-terra-app-environment-api-01.azurewebsites.net',
  androidRedirectUrl: 'msauth://health.bic.ivchealth/2jmj7l5rSw0yVb%2FvlWAYkK%2FYBwk%3D',
  iosRedirectUrl: 'msauth.health.bic.ivchealth://auth',
};

export const environment = {
  environmentName: 'allegra-staging-rsa',
  production: false,
  appEnvironmentBaseUrl: 'https://alg-uat-sa-terra-app-environment-api-01.azurewebsites.net',
  availablelanguages: [
    { code: 'en', text: 'English', dir: 'ltr', contentSupport: true, fontFamily: 'ApaxThree' },
    { code: 'af', text: 'Afrikaans', dir: 'ltr', contentSupport: true, fontFamily: 'ApaxThree' },
  ],
  DefaultLobbyID: '',
};
