import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'healthFindr/cancellation/:cancelationId',
    loadChildren: () => import('./pages/healthFindr/booking-cancellation/booking-cancellation.module').then((m) => m.BookingCancellationModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/healthFindr/healthFindr.module').then((m) => m.HealthFindrModule),
  },
  {
    path: 'healthFindr',
    loadChildren: () => import('./pages/healthFindr/healthFindr.module').then((m) => m.HealthFindrModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
