/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConsumerSearchModel } from '../../models/consumer-search-model';
import { HealthcareServiceConsumerModel } from '../../models/healthcare-service-consumer-model';

export interface ConsumerHealthcareServicePost$Params {
  latitude?: number;
  longitude?: number;
      body?: ConsumerSearchModel
}

export function consumerHealthcareServicePost(http: HttpClient, rootUrl: string, params?: ConsumerHealthcareServicePost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>> {
  const rb = new RequestBuilder(rootUrl, consumerHealthcareServicePost.PATH, 'post');
  if (params) {
    rb.query('latitude', params.latitude, {});
    rb.query('longitude', params.longitude, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>;
    })
  );
}

consumerHealthcareServicePost.PATH = '/Consumer/HealthcareService';
