enum SecureKeys {
  Theme = 'theme',
  FontSizeModifier = 'font-size-modifier',
  Language = 'Language',
  ContentLangOverride = 'contentLangOverride',
  UrlHistory = 'urlHistory',
  FontSize = 'font-size',
  RoutingName = 'routingName',
  CurrentUser = 'CurrentUser',
  MyProfile = 'MyProfile',
  RedirectUrl = 'RedirectUrl',
  EnvironmentSettings = 'EnvironmentSettings',
  RedirectRoute = 'RedirectRoute',
  FaceScanResults = 'FaceScanResults',
  Countries = 'Countries',
  ClientResults = 'ClientResults',
  Consults = 'Consults',
}

export const SecureStorageKey = { ...SecureKeys };
export type SecureStorageKey = SecureKeys;
