/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppEnvironmentBaseService } from '../app-environment-base-service';
import { AppEnvironmentApiConfiguration } from '../app-environment-api-configuration';
import { AppEnvironmentStrictHttpResponse } from '../app-environment-strict-http-response';

import { appEnvironmentSettingsPost } from '../fn/app-environment/app-environment-settings-post';
import { AppEnvironmentSettingsPost$Params } from '../fn/app-environment/app-environment-settings-post';
import { IAppEnvironment } from '../models/i-app-environment';

@Injectable({ providedIn: 'root' })
export class AppEnvironmentAppEnvironmentService extends AppEnvironmentBaseService {
  constructor(config: AppEnvironmentApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appEnvironmentSettingsPost()` */
  static readonly AppEnvironmentSettingsPostPath = '/AppEnvironment/Settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentSettingsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentSettingsPost$Response(params?: AppEnvironmentSettingsPost$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<IAppEnvironment>> {
    return appEnvironmentSettingsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentSettingsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentSettingsPost(params?: AppEnvironmentSettingsPost$Params, context?: HttpContext): Observable<IAppEnvironment> {
    return this.appEnvironmentSettingsPost$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<IAppEnvironment>): IAppEnvironment => r.body)
    );
  }

}
