/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { broadcastMessageIdDelete } from '../fn/broadcast-message/broadcast-message-id-delete';
import { BroadcastMessageIdDelete$Params } from '../fn/broadcast-message/broadcast-message-id-delete';
import { broadcastMessageIdGet } from '../fn/broadcast-message/broadcast-message-id-get';
import { BroadcastMessageIdGet$Params } from '../fn/broadcast-message/broadcast-message-id-get';
import { BroadcastMessageModel } from '../models/broadcast-message-model';
import { broadcastMessageSavePost } from '../fn/broadcast-message/broadcast-message-save-post';
import { BroadcastMessageSavePost$Params } from '../fn/broadcast-message/broadcast-message-save-post';
import { broadcastMessageSearchPost } from '../fn/broadcast-message/broadcast-message-search-post';
import { BroadcastMessageSearchPost$Params } from '../fn/broadcast-message/broadcast-message-search-post';
import { BroadcastMessageSearchResultModel } from '../models/broadcast-message-search-result-model';

@Injectable({ providedIn: 'root' })
export class HealthFindrBroadcastMessageService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `broadcastMessageSearchPost()` */
  static readonly BroadcastMessageSearchPostPath = '/BroadcastMessage/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSearchPost$Response(params?: BroadcastMessageSearchPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<BroadcastMessageSearchResultModel>>> {
    return broadcastMessageSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSearchPost(params?: BroadcastMessageSearchPost$Params, context?: HttpContext): Observable<Array<BroadcastMessageSearchResultModel>> {
    return this.broadcastMessageSearchPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<BroadcastMessageSearchResultModel>>): Array<BroadcastMessageSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `broadcastMessageIdGet()` */
  static readonly BroadcastMessageIdGetPath = '/BroadcastMessage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdGet$Response(params: BroadcastMessageIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<BroadcastMessageModel>> {
    return broadcastMessageIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdGet(params: BroadcastMessageIdGet$Params, context?: HttpContext): Observable<BroadcastMessageModel> {
    return this.broadcastMessageIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<BroadcastMessageModel>): BroadcastMessageModel => r.body)
    );
  }

  /** Path part for operation `broadcastMessageIdDelete()` */
  static readonly BroadcastMessageIdDeletePath = '/BroadcastMessage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdDelete$Response(params: BroadcastMessageIdDelete$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<void>> {
    return broadcastMessageIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  broadcastMessageIdDelete(params: BroadcastMessageIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.broadcastMessageIdDelete$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `broadcastMessageSavePost()` */
  static readonly BroadcastMessageSavePostPath = '/BroadcastMessage/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `broadcastMessageSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSavePost$Response(params?: BroadcastMessageSavePost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return broadcastMessageSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `broadcastMessageSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  broadcastMessageSavePost(params?: BroadcastMessageSavePost$Params, context?: HttpContext): Observable<string> {
    return this.broadcastMessageSavePost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

}
