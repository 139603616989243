/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { AvailabilityDayModelAvailibilityModel } from '../models/availability-day-model-availibility-model';
import { practitionerFindrAvailabilityPost } from '../fn/practitioner-findr/practitioner-findr-availability-post';
import { PractitionerFindrAvailabilityPost$Params } from '../fn/practitioner-findr/practitioner-findr-availability-post';
import { practitionerFindrBookSlotPost } from '../fn/practitioner-findr/practitioner-findr-book-slot-post';
import { PractitionerFindrBookSlotPost$Params } from '../fn/practitioner-findr/practitioner-findr-book-slot-post';
import { practitionerFindrLobbyBookingIdGet } from '../fn/practitioner-findr/practitioner-findr-lobby-booking-id-get';
import { PractitionerFindrLobbyBookingIdGet$Params } from '../fn/practitioner-findr/practitioner-findr-lobby-booking-id-get';
import { practitionerFindrPractitionersPost } from '../fn/practitioner-findr/practitioner-findr-practitioners-post';
import { PractitionerFindrPractitionersPost$Params } from '../fn/practitioner-findr/practitioner-findr-practitioners-post';
import { PractitionerSearchResponseModel } from '../models/practitioner-search-response-model';

@Injectable({ providedIn: 'root' })
export class CalendarPractitionerFindrService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `practitionerFindrPractitionersPost()` */
  static readonly PractitionerFindrPractitionersPostPath = '/PractitionerFindr/Practitioners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrPractitionersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrPractitionersPost$Response(params?: PractitionerFindrPractitionersPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<Array<PractitionerSearchResponseModel>>> {
    return practitionerFindrPractitionersPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrPractitionersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrPractitionersPost(params?: PractitionerFindrPractitionersPost$Params, context?: HttpContext): Observable<Array<PractitionerSearchResponseModel>> {
    return this.practitionerFindrPractitionersPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<Array<PractitionerSearchResponseModel>>): Array<PractitionerSearchResponseModel> => r.body)
    );
  }

  /** Path part for operation `practitionerFindrAvailabilityPost()` */
  static readonly PractitionerFindrAvailabilityPostPath = '/PractitionerFindr/Availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrAvailabilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrAvailabilityPost$Response(params?: PractitionerFindrAvailabilityPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<AvailabilityDayModelAvailibilityModel>> {
    return practitionerFindrAvailabilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrAvailabilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrAvailabilityPost(params?: PractitionerFindrAvailabilityPost$Params, context?: HttpContext): Observable<AvailabilityDayModelAvailibilityModel> {
    return this.practitionerFindrAvailabilityPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<AvailabilityDayModelAvailibilityModel>): AvailabilityDayModelAvailibilityModel => r.body)
    );
  }

  /** Path part for operation `practitionerFindrBookSlotPost()` */
  static readonly PractitionerFindrBookSlotPostPath = '/PractitionerFindr/BookSlot';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrBookSlotPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrBookSlotPost$Response(params?: PractitionerFindrBookSlotPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<void>> {
    return practitionerFindrBookSlotPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrBookSlotPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerFindrBookSlotPost(params?: PractitionerFindrBookSlotPost$Params, context?: HttpContext): Observable<void> {
    return this.practitionerFindrBookSlotPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `practitionerFindrLobbyBookingIdGet()` */
  static readonly PractitionerFindrLobbyBookingIdGetPath = '/PractitionerFindr/Lobby/{bookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerFindrLobbyBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerFindrLobbyBookingIdGet$Response(params: PractitionerFindrLobbyBookingIdGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return practitionerFindrLobbyBookingIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerFindrLobbyBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerFindrLobbyBookingIdGet(params: PractitionerFindrLobbyBookingIdGet$Params, context?: HttpContext): Observable<string> {
    return this.practitionerFindrLobbyBookingIdGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

}
