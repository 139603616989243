/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';

import { consumerGet } from '../fn/consumer/consumer-get';
import { ConsumerGet$Params } from '../fn/consumer/consumer-get';
import { consumerPatientSummaryGet } from '../fn/consumer/consumer-patient-summary-get';
import { ConsumerPatientSummaryGet$Params } from '../fn/consumer/consumer-patient-summary-get';
import { consumerPatientSummaryPost } from '../fn/consumer/consumer-patient-summary-post';
import { ConsumerPatientSummaryPost$Params } from '../fn/consumer/consumer-patient-summary-post';
import { consumerPersonIdMyPhotoGet } from '../fn/consumer/consumer-person-id-my-photo-get';
import { ConsumerPersonIdMyPhotoGet$Params } from '../fn/consumer/consumer-person-id-my-photo-get';
import { consumerPersonIdMyThumbnailGet } from '../fn/consumer/consumer-person-id-my-thumbnail-get';
import { ConsumerPersonIdMyThumbnailGet$Params } from '../fn/consumer/consumer-person-id-my-thumbnail-get';
import { consumerPersonIdPhotoGet } from '../fn/consumer/consumer-person-id-photo-get';
import { ConsumerPersonIdPhotoGet$Params } from '../fn/consumer/consumer-person-id-photo-get';
import { consumerPersonIdThumbnailGet } from '../fn/consumer/consumer-person-id-thumbnail-get';
import { ConsumerPersonIdThumbnailGet$Params } from '../fn/consumer/consumer-person-id-thumbnail-get';
import { consumerPhotoPost } from '../fn/consumer/consumer-photo-post';
import { ConsumerPhotoPost$Params } from '../fn/consumer/consumer-photo-post';
import { consumerPost } from '../fn/consumer/consumer-post';
import { ConsumerPost$Params } from '../fn/consumer/consumer-post';
import { PatientModel } from '../models/patient-model';
import { PatientSummaryModel } from '../models/patient-summary-model';

@Injectable({ providedIn: 'root' })
export class MPIConsumerService extends MPIBaseService {
  constructor(config: MPIApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerPersonIdPhotoGet()` */
  static readonly ConsumerPersonIdPhotoGetPath = '/Consumer/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdPhotoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet$Response(params: ConsumerPersonIdPhotoGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerPersonIdPhotoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdPhotoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet(params: ConsumerPersonIdPhotoGet$Params, context?: HttpContext): Observable<void> {
    return this.consumerPersonIdPhotoGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerPersonIdThumbnailGet()` */
  static readonly ConsumerPersonIdThumbnailGetPath = '/Consumer/{personId}/Thumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdThumbnailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet$Response(params: ConsumerPersonIdThumbnailGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerPersonIdThumbnailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdThumbnailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet(params: ConsumerPersonIdThumbnailGet$Params, context?: HttpContext): Observable<void> {
    return this.consumerPersonIdThumbnailGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerPersonIdMyPhotoGet()` */
  static readonly ConsumerPersonIdMyPhotoGetPath = '/Consumer/{personId}/MyPhoto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdMyPhotoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyPhotoGet$Response(params: ConsumerPersonIdMyPhotoGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerPersonIdMyPhotoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdMyPhotoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyPhotoGet(params: ConsumerPersonIdMyPhotoGet$Params, context?: HttpContext): Observable<void> {
    return this.consumerPersonIdMyPhotoGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerPersonIdMyThumbnailGet()` */
  static readonly ConsumerPersonIdMyThumbnailGetPath = '/Consumer/{personId}/MyThumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdMyThumbnailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyThumbnailGet$Response(params: ConsumerPersonIdMyThumbnailGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerPersonIdMyThumbnailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdMyThumbnailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdMyThumbnailGet(params: ConsumerPersonIdMyThumbnailGet$Params, context?: HttpContext): Observable<void> {
    return this.consumerPersonIdMyThumbnailGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerGet()` */
  static readonly ConsumerGetPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Response(params?: ConsumerGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PatientModel>> {
    return consumerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet(params?: ConsumerGet$Params, context?: HttpContext): Observable<PatientModel> {
    return this.consumerGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PatientModel>): PatientModel => r.body)
    );
  }

  /** Path part for operation `consumerPost()` */
  static readonly ConsumerPostPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Response(params?: ConsumerPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return consumerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost(params?: ConsumerPost$Params, context?: HttpContext): Observable<string> {
    return this.consumerPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `consumerPatientSummaryGet()` */
  static readonly ConsumerPatientSummaryGetPath = '/Consumer/PatientSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPatientSummaryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPatientSummaryGet$Response(params?: ConsumerPatientSummaryGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PatientSummaryModel>> {
    return consumerPatientSummaryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPatientSummaryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPatientSummaryGet(params?: ConsumerPatientSummaryGet$Params, context?: HttpContext): Observable<PatientSummaryModel> {
    return this.consumerPatientSummaryGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PatientSummaryModel>): PatientSummaryModel => r.body)
    );
  }

  /** Path part for operation `consumerPatientSummaryPost()` */
  static readonly ConsumerPatientSummaryPostPath = '/Consumer/PatientSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPatientSummaryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPatientSummaryPost$Response(params?: ConsumerPatientSummaryPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PatientSummaryModel>> {
    return consumerPatientSummaryPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPatientSummaryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPatientSummaryPost(params?: ConsumerPatientSummaryPost$Params, context?: HttpContext): Observable<PatientSummaryModel> {
    return this.consumerPatientSummaryPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PatientSummaryModel>): PatientSummaryModel => r.body)
    );
  }

  /** Path part for operation `consumerPhotoPost()` */
  static readonly ConsumerPhotoPostPath = '/Consumer/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPhotoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Response(params?: ConsumerPhotoPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return consumerPhotoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPhotoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost(params?: ConsumerPhotoPost$Params, context?: HttpContext): Observable<string> {
    return this.consumerPhotoPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

}
