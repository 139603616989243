import { Injectable } from '@angular/core';
import { SecureStorageService } from '../storage/secure-storage.service';
import DeviceCountry from 'src/app/plugins/device-country/device-country-plugin';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { LoadingService } from '../loading-service/loading.service';
import { AppEnvironmentAppEnvironmentService } from 'src/app/api/proxy/app-environment/app-environment-services';
import { DeviceIdentifierModel, IAppEnvironment } from 'src/app/api/proxy/app-environment/app-environment-models';
import { firstValueFrom } from 'rxjs';
import { PromptService } from '../promtp-service/prompt.service';
import { StorageKey } from '../storage/models/storage-key.enum';
import { EventService } from '../events/event.service';
import { environment } from 'src/environments/dev/environment';
import { Console } from 'console';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigService {
  private _IDeviceIdentifer: DeviceIdentifierModel | null = null;
  private _IAppEnvironment: IAppEnvironment | null = null;

  constructor(
    private _loading: LoadingService,
    // private _PromptService: PromptService,
    private _AppEnvironmentAppEnvironmentService: AppEnvironmentAppEnvironmentService,
    private events: EventService,
    private _StorageService: SecureStorageService
  ) {
    this._loading.registerSilentUrl(AppEnvironmentAppEnvironmentService.AppEnvironmentSettingsPostPath);
    this._loading.registerSilentUrl('BroadcastMessages');
    this._loading.registerSilentUrl('RequestReservation');
  }

  public async LoadConfigurationAsync(): Promise<void> {
    if (this._IDeviceIdentifer == null) {
      //setup config request object
      var countrySetting = await DeviceCountry.getCurrentCountry();

      var appInfo: AppInfo = {
        name: window.location.hostname,
        id: '',
        version: '',
        build: '',
      };

      if (Capacitor.getPlatform() != 'web') {
        var appInfo = await App.getInfo();
      }

      var request: DeviceIdentifierModel = {
        deviceType: countrySetting.device,
        countryCode: countrySetting.country,
        name: appInfo.name,
        id: appInfo.id,
        version: appInfo.version,
        build: appInfo.build,
        environmentName: environment.environmentName,
        production: environment.production,
      };
      this._IDeviceIdentifer = request;

      var mustLoadSettings = true;
      var prevSettings = await this._StorageService.get(StorageKey.EnvironmentSettings);
      if (prevSettings) {
        try {
          var mdl: IAppEnvironment = JSON.parse(prevSettings);
          this._IAppEnvironment = mdl;

          mustLoadSettings = false;

          if (mdl.expiryUTC) {
            const utc = moment(new Date()).utc().valueOf();
            var utcNow = moment.utc(utc).toDate();

            var dt = new Date(mdl.expiryUTC);
            if (dt < utcNow) {
              mustLoadSettings = true;
            }
          } else {
            mustLoadSettings = true;
          }
        } catch {
          mustLoadSettings = true;
        }
      }

      if (mustLoadSettings) {
        var promise = firstValueFrom(
          this._AppEnvironmentAppEnvironmentService.appEnvironmentSettingsPost({
            body: request,
          })
        );
        promise
          .then(async (result) => {
            await this._StorageService.set(StorageKey.EnvironmentSettings, JSON.stringify(result));
            return result;
          })
          .catch(async (err) => {
            if (prevSettings) return JSON.parse(prevSettings) as IAppEnvironment;
            else throw new Error('Configuration not loaded - please try again later');
          });
        this._IAppEnvironment = await promise;
      }
      this._loading.registerSilentUrl(this._IAppEnvironment!.authenticateOptions?.accessTokenEndpoint!);
      this._loading.registerSilentUrl(this._IAppEnvironment!.authenticateOptions?.logoutUrl!);
      this.events.appConfigLoaded.emit(true);
    }
  }

  public Loaded(): boolean {
    return this._IDeviceIdentifer != null && this._IAppEnvironment != null;
  }

  public DeviceConfig(): DeviceIdentifierModel {
    if (!this._IDeviceIdentifer) throw new Error('Configuration not loaded');

    return this._IDeviceIdentifer!;
  }

  public EnvironmentConfig(): IAppEnvironment {
    if (!this._IAppEnvironment) throw new Error('Enviroment not loaded');
    return this._IAppEnvironment;
  }

  public EnvironmentConfigOrEmpty(): IAppEnvironment | null {
    return this._IAppEnvironment;
  }
}
