/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { anonymousBroadcastMessagesPost } from '../fn/anonymous/anonymous-broadcast-messages-post';
import { AnonymousBroadcastMessagesPost$Params } from '../fn/anonymous/anonymous-broadcast-messages-post';
import { anonymousCommunicationBlobContentIdGet } from '../fn/anonymous/anonymous-communication-blob-content-id-get';
import { AnonymousCommunicationBlobContentIdGet$Params } from '../fn/anonymous/anonymous-communication-blob-content-id-get';
import { anonymousCommunicationBlobContentIdPost } from '../fn/anonymous/anonymous-communication-blob-content-id-post';
import { AnonymousCommunicationBlobContentIdPost$Params } from '../fn/anonymous/anonymous-communication-blob-content-id-post';
import { anonymousCommunicationBlobContentPost } from '../fn/anonymous/anonymous-communication-blob-content-post';
import { AnonymousCommunicationBlobContentPost$Params } from '../fn/anonymous/anonymous-communication-blob-content-post';
import { anonymousFhirIdSpecialityValidityGet } from '../fn/anonymous/anonymous-fhir-id-speciality-validity-get';
import { AnonymousFhirIdSpecialityValidityGet$Params } from '../fn/anonymous/anonymous-fhir-id-speciality-validity-get';
import { anonymousFormsPost } from '../fn/anonymous/anonymous-forms-post';
import { AnonymousFormsPost$Params } from '../fn/anonymous/anonymous-forms-post';
import { anonymousPromotionalLinkIdGet } from '../fn/anonymous/anonymous-promotional-link-id-get';
import { AnonymousPromotionalLinkIdGet$Params } from '../fn/anonymous/anonymous-promotional-link-id-get';
import { anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet } from '../fn/anonymous/anonymous-service-confirmation-email-bin-organization-fhir-id-health-care-service-fhir-id-location-fhir-id-speciality-fhir-id-get';
import { AnonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Params } from '../fn/anonymous/anonymous-service-confirmation-email-bin-organization-fhir-id-health-care-service-fhir-id-location-fhir-id-speciality-fhir-id-get';
import { anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet } from '../fn/anonymous/anonymous-service-hours-of-operation-health-care-service-fhir-id-location-fhir-id-speciality-fhir-id-get';
import { AnonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Params } from '../fn/anonymous/anonymous-service-hours-of-operation-health-care-service-fhir-id-location-fhir-id-speciality-fhir-id-get';
import { anonymousSpecialityPromotionalLinkIdGet } from '../fn/anonymous/anonymous-speciality-promotional-link-id-get';
import { AnonymousSpecialityPromotionalLinkIdGet$Params } from '../fn/anonymous/anonymous-speciality-promotional-link-id-get';
import { PromotionalLinkModel } from '../models/promotional-link-model';
import { PromotionalLinkSpeciality } from '../models/promotional-link-speciality';
import { ServiceScheduleModel } from '../models/service-schedule-model';
import { SpecialityValidityResultModel } from '../models/speciality-validity-result-model';

@Injectable({ providedIn: 'root' })
export class HealthFindrAnonymousService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `anonymousPromotionalLinkIdGet()` */
  static readonly AnonymousPromotionalLinkIdGetPath = '/Anonymous/PromotionalLink/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousPromotionalLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousPromotionalLinkIdGet$Response(params: AnonymousPromotionalLinkIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<PromotionalLinkModel>> {
    return anonymousPromotionalLinkIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousPromotionalLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousPromotionalLinkIdGet(params: AnonymousPromotionalLinkIdGet$Params, context?: HttpContext): Observable<PromotionalLinkModel> {
    return this.anonymousPromotionalLinkIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<PromotionalLinkModel>): PromotionalLinkModel => r.body)
    );
  }

  /** Path part for operation `anonymousSpecialityPromotionalLinkIdGet()` */
  static readonly AnonymousSpecialityPromotionalLinkIdGetPath = '/Anonymous/SpecialityPromotionalLink/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousSpecialityPromotionalLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityPromotionalLinkIdGet$Response(params: AnonymousSpecialityPromotionalLinkIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<PromotionalLinkSpeciality>> {
    return anonymousSpecialityPromotionalLinkIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousSpecialityPromotionalLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityPromotionalLinkIdGet(params: AnonymousSpecialityPromotionalLinkIdGet$Params, context?: HttpContext): Observable<PromotionalLinkSpeciality> {
    return this.anonymousSpecialityPromotionalLinkIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<PromotionalLinkSpeciality>): PromotionalLinkSpeciality => r.body)
    );
  }

  /** Path part for operation `anonymousBroadcastMessagesPost()` */
  static readonly AnonymousBroadcastMessagesPostPath = '/Anonymous/BroadcastMessages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousBroadcastMessagesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousBroadcastMessagesPost$Response(params?: AnonymousBroadcastMessagesPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<string>>> {
    return anonymousBroadcastMessagesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousBroadcastMessagesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousBroadcastMessagesPost(params?: AnonymousBroadcastMessagesPost$Params, context?: HttpContext): Observable<Array<string>> {
    return this.anonymousBroadcastMessagesPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `anonymousFormsPost()` */
  static readonly AnonymousFormsPostPath = '/Anonymous/Forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFormsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousFormsPost$Response(params?: AnonymousFormsPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<string>>> {
    return anonymousFormsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousFormsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousFormsPost(params?: AnonymousFormsPost$Params, context?: HttpContext): Observable<Array<string>> {
    return this.anonymousFormsPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `anonymousFhirIdSpecialityValidityGet()` */
  static readonly AnonymousFhirIdSpecialityValidityGetPath = '/Anonymous/{fhirId}/SpecialityValidity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFhirIdSpecialityValidityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdSpecialityValidityGet$Response(params: AnonymousFhirIdSpecialityValidityGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<SpecialityValidityResultModel>>> {
    return anonymousFhirIdSpecialityValidityGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousFhirIdSpecialityValidityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdSpecialityValidityGet(params: AnonymousFhirIdSpecialityValidityGet$Params, context?: HttpContext): Observable<Array<SpecialityValidityResultModel>> {
    return this.anonymousFhirIdSpecialityValidityGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<SpecialityValidityResultModel>>): Array<SpecialityValidityResultModel> => r.body)
    );
  }

  /** Path part for operation `anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet()` */
  static readonly AnonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGetPath = '/Anonymous/ServiceHoursOfOperation/{healthCareServiceFhirId}/{locationFhirId}/{specialityFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response(params: AnonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<ServiceScheduleModel>> {
    return anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet(params: AnonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Params, context?: HttpContext): Observable<ServiceScheduleModel> {
    return this.anonymousServiceHoursOfOperationHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<ServiceScheduleModel>): ServiceScheduleModel => r.body)
    );
  }

  /** Path part for operation `anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet()` */
  static readonly AnonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGetPath = '/Anonymous/ServiceConfirmationEmailBin/{organizationFhirId}/{healthCareServiceFhirId}/{locationFhirId}/{specialityFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response(params: AnonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<string>>> {
    return anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet(params: AnonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.anonymousServiceConfirmationEmailBinOrganizationFhirIdHealthCareServiceFhirIdLocationFhirIdSpecialityFhirIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `anonymousCommunicationBlobContentIdPost()` */
  static readonly AnonymousCommunicationBlobContentIdPostPath = '/Anonymous/CommunicationBlobContentId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousCommunicationBlobContentIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousCommunicationBlobContentIdPost$Response(params?: AnonymousCommunicationBlobContentIdPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return anonymousCommunicationBlobContentIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousCommunicationBlobContentIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousCommunicationBlobContentIdPost(params?: AnonymousCommunicationBlobContentIdPost$Params, context?: HttpContext): Observable<string> {
    return this.anonymousCommunicationBlobContentIdPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `anonymousCommunicationBlobContentPost()` */
  static readonly AnonymousCommunicationBlobContentPostPath = '/Anonymous/CommunicationBlobContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousCommunicationBlobContentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousCommunicationBlobContentPost$Response(params?: AnonymousCommunicationBlobContentPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return anonymousCommunicationBlobContentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousCommunicationBlobContentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  anonymousCommunicationBlobContentPost(params?: AnonymousCommunicationBlobContentPost$Params, context?: HttpContext): Observable<string> {
    return this.anonymousCommunicationBlobContentPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `anonymousCommunicationBlobContentIdGet()` */
  static readonly AnonymousCommunicationBlobContentIdGetPath = '/Anonymous/CommunicationBlobContent/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousCommunicationBlobContentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousCommunicationBlobContentIdGet$Response(params: AnonymousCommunicationBlobContentIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return anonymousCommunicationBlobContentIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousCommunicationBlobContentIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousCommunicationBlobContentIdGet(params: AnonymousCommunicationBlobContentIdGet$Params, context?: HttpContext): Observable<string> {
    return this.anonymousCommunicationBlobContentIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

}
