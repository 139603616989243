/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { KeyValueCodeModel } from '../../models/key-value-code-model';

export interface AdminFhirIdKeyValueSpecialitiesLanguageRegionGet$Params {
  fhirId: string;
  languageRegion: string;
}

export function adminFhirIdKeyValueSpecialitiesLanguageRegionGet(http: HttpClient, rootUrl: string, params: AdminFhirIdKeyValueSpecialitiesLanguageRegionGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>> {
  const rb = new RequestBuilder(rootUrl, adminFhirIdKeyValueSpecialitiesLanguageRegionGet.PATH, 'get');
  if (params) {
    rb.path('fhirId', params.fhirId, {});
    rb.path('languageRegion', params.languageRegion, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>;
    })
  );
}

adminFhirIdKeyValueSpecialitiesLanguageRegionGet.PATH = '/Admin/{fhirId}/KeyValueSpecialities/{languageRegion}';
