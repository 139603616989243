/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BookingStrictHttpResponse } from '../../booking-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HealthCareServiceModel } from '../../models/health-care-service-model';
import { HealthCareServiceRadiusRequest } from '../../models/health-care-service-radius-request';

export interface HealthFindrHealthCareServicesRadiusPost$Params {
      body?: HealthCareServiceRadiusRequest
}

export function healthFindrHealthCareServicesRadiusPost(http: HttpClient, rootUrl: string, params?: HealthFindrHealthCareServicesRadiusPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<Array<HealthCareServiceModel>>> {
  const rb = new RequestBuilder(rootUrl, healthFindrHealthCareServicesRadiusPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as BookingStrictHttpResponse<Array<HealthCareServiceModel>>;
    })
  );
}

healthFindrHealthCareServicesRadiusPost.PATH = '/HealthFindr/HealthCareServicesRadius';
