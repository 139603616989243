/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrganizationConfigurationModel } from '../../models/organization-configuration-model';

export interface AnonymousOrganizationConfigurationGet$Params {
  parentEntityId?: string;
}

export function anonymousOrganizationConfigurationGet(http: HttpClient, rootUrl: string, params?: AnonymousOrganizationConfigurationGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationConfigurationModel>> {
  const rb = new RequestBuilder(rootUrl, anonymousOrganizationConfigurationGet.PATH, 'get');
  if (params) {
    rb.query('parentEntityId', params.parentEntityId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<OrganizationConfigurationModel>;
    })
  );
}

anonymousOrganizationConfigurationGet.PATH = '/Anonymous/OrganizationConfiguration';
