/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrganizationSearchResultModel } from '../../models/organization-search-result-model';

export interface ConsumerOrganizationsPageSizeGet$Params {
  pageSize: number;
}

export function consumerOrganizationsPageSizeGet(http: HttpClient, rootUrl: string, params: ConsumerOrganizationsPageSizeGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>> {
  const rb = new RequestBuilder(rootUrl, consumerOrganizationsPageSizeGet.PATH, 'get');
  if (params) {
    rb.path('pageSize', params.pageSize, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>;
    })
  );
}

consumerOrganizationsPageSizeGet.PATH = '/Consumer/Organizations/{pageSize}';
