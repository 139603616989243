import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Injectable } from '@angular/core';
import { SecureStorageKey } from './models/secure-storage-key.enum';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService {

	/*	private loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
			false
		);
		public loaded$: Observable<boolean> = this.loaded.asObservable();*/

	constructor(private storageService: StorageService) { }


  public async set(key: SecureStorageKey, value: string): Promise<boolean> {
    value = encodeURIComponent(value);
    return await SecureStoragePlugin.set({ key, value })
      .then((response) => response.value)
      .catch(async (err) => {
        return false;
      });
  }

  public async get(key: SecureStorageKey): Promise<string | null> {
    var keys = await SecureStoragePlugin.keys();
    if (keys && keys.value.includes(key)) {
      return await SecureStoragePlugin.get({ key })
        .then((response) => decodeURIComponent(response.value))
        .catch(async (err) => {
          return null;
        });
    } else {
      return null;
    }
  }

  public async remove(key: SecureStorageKey): Promise<boolean> {
    var keys = await SecureStoragePlugin.keys();
    if (keys && keys.value.includes(key)) {
      return await SecureStoragePlugin.remove({ key: key })
        .then((response) => response.value)
        .catch(() => false);
    } else {
      return false;
    }
  }

  public async clear(): Promise<boolean> {
    return await SecureStoragePlugin.clear()
      .then((response) => response.value)
      .catch(() => false);
  }

	public async clearSafeData() {
		var redirect = await this.get(SecureStorageKey.RedirectUrl);
		var urlHistory = await this.get(SecureStorageKey.UrlHistory);
		var routingName = await this.get(SecureStorageKey.RoutingName);
		var redirectRoute = await this.get(SecureStorageKey.RedirectRoute);

    await this.clear();

		if (redirect)
			await this.set(SecureStorageKey.RedirectUrl, redirect);
		if (urlHistory)
			await this.set(SecureStorageKey.UrlHistory, urlHistory);
		if (routingName)
			await this.set(SecureStorageKey.RoutingName, routingName);
		if (redirectRoute)
			await this.set(SecureStorageKey.RedirectRoute, redirectRoute);
	}

  public async keys(): Promise<Array<SecureStorageKey>> {
    return await SecureStoragePlugin.keys()
      .then((response) => response.value as SecureStorageKey[])
      .catch(() => []);
  }
}
