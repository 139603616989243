/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { KeyValueModel } from '../models/key-value-model';
import { medicalAdvisorAvailibilityPost } from '../fn/medical-advisor/medical-advisor-availibility-post';
import { MedicalAdvisorAvailibilityPost$Params } from '../fn/medical-advisor/medical-advisor-availibility-post';
import { MedicalAdvisorBookingDetailModel } from '../models/medical-advisor-booking-detail-model';
import { medicalAdvisorBookingDetailUrnBookingIdGet } from '../fn/medical-advisor/medical-advisor-booking-detail-urn-booking-id-get';
import { MedicalAdvisorBookingDetailUrnBookingIdGet$Params } from '../fn/medical-advisor/medical-advisor-booking-detail-urn-booking-id-get';
import { medicalAdvisorCancelBookingPost } from '../fn/medical-advisor/medical-advisor-cancel-booking-post';
import { MedicalAdvisorCancelBookingPost$Params } from '../fn/medical-advisor/medical-advisor-cancel-booking-post';
import { medicalAdvisorConfirmBookingPost } from '../fn/medical-advisor/medical-advisor-confirm-booking-post';
import { MedicalAdvisorConfirmBookingPost$Params } from '../fn/medical-advisor/medical-advisor-confirm-booking-post';
import { medicalAdvisorConfirmReservationPost } from '../fn/medical-advisor/medical-advisor-confirm-reservation-post';
import { MedicalAdvisorConfirmReservationPost$Params } from '../fn/medical-advisor/medical-advisor-confirm-reservation-post';
import { medicalAdvisorFhirIdSpecialitiesGet } from '../fn/medical-advisor/medical-advisor-fhir-id-specialities-get';
import { MedicalAdvisorFhirIdSpecialitiesGet$Params } from '../fn/medical-advisor/medical-advisor-fhir-id-specialities-get';
import { medicalAdvisorHealthCareServicesPost } from '../fn/medical-advisor/medical-advisor-health-care-services-post';
import { MedicalAdvisorHealthCareServicesPost$Params } from '../fn/medical-advisor/medical-advisor-health-care-services-post';
import { medicalAdvisorRequestReservationPost } from '../fn/medical-advisor/medical-advisor-request-reservation-post';
import { MedicalAdvisorRequestReservationPost$Params } from '../fn/medical-advisor/medical-advisor-request-reservation-post';
import { OrganizationSearchResultModel } from '../models/organization-search-result-model';
import { UrnAvailabilityDayModelAvailibilityModel } from '../models/urn-availability-day-model-availibility-model';

@Injectable({ providedIn: 'root' })
export class CalendarMedicalAdvisorService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `medicalAdvisorFhirIdSpecialitiesGet()` */
  static readonly MedicalAdvisorFhirIdSpecialitiesGetPath = '/MedicalAdvisor/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorFhirIdSpecialitiesGet$Response(params: MedicalAdvisorFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<Array<KeyValueModel>>> {
    return medicalAdvisorFhirIdSpecialitiesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorFhirIdSpecialitiesGet(params: MedicalAdvisorFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<Array<KeyValueModel>> {
    return this.medicalAdvisorFhirIdSpecialitiesGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<Array<KeyValueModel>>): Array<KeyValueModel> => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorHealthCareServicesPost()` */
  static readonly MedicalAdvisorHealthCareServicesPostPath = '/MedicalAdvisor/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorHealthCareServicesPost$Response(params?: MedicalAdvisorHealthCareServicesPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<Array<OrganizationSearchResultModel>>> {
    return medicalAdvisorHealthCareServicesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorHealthCareServicesPost(params?: MedicalAdvisorHealthCareServicesPost$Params, context?: HttpContext): Observable<Array<OrganizationSearchResultModel>> {
    return this.medicalAdvisorHealthCareServicesPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<Array<OrganizationSearchResultModel>>): Array<OrganizationSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorAvailibilityPost()` */
  static readonly MedicalAdvisorAvailibilityPostPath = '/MedicalAdvisor/Availibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorAvailibilityPost$Response(params?: MedicalAdvisorAvailibilityPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>> {
    return medicalAdvisorAvailibilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorAvailibilityPost(params?: MedicalAdvisorAvailibilityPost$Params, context?: HttpContext): Observable<UrnAvailabilityDayModelAvailibilityModel> {
    return this.medicalAdvisorAvailibilityPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>): UrnAvailabilityDayModelAvailibilityModel => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorRequestReservationPost()` */
  static readonly MedicalAdvisorRequestReservationPostPath = '/MedicalAdvisor/RequestReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorRequestReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorRequestReservationPost$Response(params?: MedicalAdvisorRequestReservationPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return medicalAdvisorRequestReservationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorRequestReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorRequestReservationPost(params?: MedicalAdvisorRequestReservationPost$Params, context?: HttpContext): Observable<string> {
    return this.medicalAdvisorRequestReservationPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorConfirmReservationPost()` */
  static readonly MedicalAdvisorConfirmReservationPostPath = '/MedicalAdvisor/ConfirmReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorConfirmReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmReservationPost$Response(params?: MedicalAdvisorConfirmReservationPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return medicalAdvisorConfirmReservationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorConfirmReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmReservationPost(params?: MedicalAdvisorConfirmReservationPost$Params, context?: HttpContext): Observable<string> {
    return this.medicalAdvisorConfirmReservationPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorConfirmBookingPost()` */
  static readonly MedicalAdvisorConfirmBookingPostPath = '/MedicalAdvisor/ConfirmBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorConfirmBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmBookingPost$Response(params?: MedicalAdvisorConfirmBookingPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return medicalAdvisorConfirmBookingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorConfirmBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorConfirmBookingPost(params?: MedicalAdvisorConfirmBookingPost$Params, context?: HttpContext): Observable<string> {
    return this.medicalAdvisorConfirmBookingPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorBookingDetailUrnBookingIdGet()` */
  static readonly MedicalAdvisorBookingDetailUrnBookingIdGetPath = '/MedicalAdvisor/BookingDetail/{urnBookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorBookingDetailUrnBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorBookingDetailUrnBookingIdGet$Response(params: MedicalAdvisorBookingDetailUrnBookingIdGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<MedicalAdvisorBookingDetailModel>> {
    return medicalAdvisorBookingDetailUrnBookingIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorBookingDetailUrnBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  medicalAdvisorBookingDetailUrnBookingIdGet(params: MedicalAdvisorBookingDetailUrnBookingIdGet$Params, context?: HttpContext): Observable<MedicalAdvisorBookingDetailModel> {
    return this.medicalAdvisorBookingDetailUrnBookingIdGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<MedicalAdvisorBookingDetailModel>): MedicalAdvisorBookingDetailModel => r.body)
    );
  }

  /** Path part for operation `medicalAdvisorCancelBookingPost()` */
  static readonly MedicalAdvisorCancelBookingPostPath = '/MedicalAdvisor/CancelBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `medicalAdvisorCancelBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorCancelBookingPost$Response(params?: MedicalAdvisorCancelBookingPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return medicalAdvisorCancelBookingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `medicalAdvisorCancelBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  medicalAdvisorCancelBookingPost(params?: MedicalAdvisorCancelBookingPost$Params, context?: HttpContext): Observable<string> {
    return this.medicalAdvisorCancelBookingPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

}
