/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { FormModel } from '../models/form-model';
import { FormSearchResultModel } from '../models/form-search-result-model';
import { formsIdDelete } from '../fn/forms/forms-id-delete';
import { FormsIdDelete$Params } from '../fn/forms/forms-id-delete';
import { formsIdGet } from '../fn/forms/forms-id-get';
import { FormsIdGet$Params } from '../fn/forms/forms-id-get';
import { formsSavePost } from '../fn/forms/forms-save-post';
import { FormsSavePost$Params } from '../fn/forms/forms-save-post';
import { formsSearchPost } from '../fn/forms/forms-search-post';
import { FormsSearchPost$Params } from '../fn/forms/forms-search-post';

@Injectable({ providedIn: 'root' })
export class HealthFindrFormsService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formsSearchPost()` */
  static readonly FormsSearchPostPath = '/Forms/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formsSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formsSearchPost$Response(params?: FormsSearchPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<FormSearchResultModel>>> {
    return formsSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formsSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formsSearchPost(params?: FormsSearchPost$Params, context?: HttpContext): Observable<Array<FormSearchResultModel>> {
    return this.formsSearchPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<FormSearchResultModel>>): Array<FormSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `formsIdGet()` */
  static readonly FormsIdGetPath = '/Forms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  formsIdGet$Response(params: FormsIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<FormModel>> {
    return formsIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formsIdGet(params: FormsIdGet$Params, context?: HttpContext): Observable<FormModel> {
    return this.formsIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<FormModel>): FormModel => r.body)
    );
  }

  /** Path part for operation `formsIdDelete()` */
  static readonly FormsIdDeletePath = '/Forms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  formsIdDelete$Response(params: FormsIdDelete$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<void>> {
    return formsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formsIdDelete(params: FormsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.formsIdDelete$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `formsSavePost()` */
  static readonly FormsSavePostPath = '/Forms/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formsSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formsSavePost$Response(params?: FormsSavePost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return formsSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formsSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  formsSavePost(params?: FormsSavePost$Params, context?: HttpContext): Observable<string> {
    return this.formsSavePost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

}
