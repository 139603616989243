/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { adminFhirIdHealthCareServicesGet } from '../fn/admin/admin-fhir-id-health-care-services-get';
import { AdminFhirIdHealthCareServicesGet$Params } from '../fn/admin/admin-fhir-id-health-care-services-get';
import { adminFhirIdSpecialitiesGet } from '../fn/admin/admin-fhir-id-specialities-get';
import { AdminFhirIdSpecialitiesGet$Params } from '../fn/admin/admin-fhir-id-specialities-get';
import { adminLocationsSpecialitiesHealthCareServiceFhirIdGet } from '../fn/admin/admin-locations-specialities-health-care-service-fhir-id-get';
import { AdminLocationsSpecialitiesHealthCareServiceFhirIdGet$Params } from '../fn/admin/admin-locations-specialities-health-care-service-fhir-id-get';
import { adminOrganizationsGet } from '../fn/admin/admin-organizations-get';
import { AdminOrganizationsGet$Params } from '../fn/admin/admin-organizations-get';
import { EntityMetadataModel } from '../models/entity-metadata-model';
import { HealthCareServiceMetadataModel } from '../models/health-care-service-metadata-model';
import { KeyValueModel } from '../models/key-value-model';
import { OrganizationMetadataModel } from '../models/organization-metadata-model';

@Injectable({ providedIn: 'root' })
export class HealthFindrAdminService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminOrganizationsGet()` */
  static readonly AdminOrganizationsGetPath = '/Admin/Organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminOrganizationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationsGet$Response(params?: AdminOrganizationsGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<OrganizationMetadataModel>>> {
    return adminOrganizationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminOrganizationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminOrganizationsGet(params?: AdminOrganizationsGet$Params, context?: HttpContext): Observable<Array<OrganizationMetadataModel>> {
    return this.adminOrganizationsGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<OrganizationMetadataModel>>): Array<OrganizationMetadataModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdHealthCareServicesGet()` */
  static readonly AdminFhirIdHealthCareServicesGetPath = '/Admin/{fhirId}/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthCareServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthCareServicesGet$Response(params: AdminFhirIdHealthCareServicesGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<EntityMetadataModel>>> {
    return adminFhirIdHealthCareServicesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthCareServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthCareServicesGet(params: AdminFhirIdHealthCareServicesGet$Params, context?: HttpContext): Observable<Array<EntityMetadataModel>> {
    return this.adminFhirIdHealthCareServicesGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<EntityMetadataModel>>): Array<EntityMetadataModel> => r.body)
    );
  }

  /** Path part for operation `adminLocationsSpecialitiesHealthCareServiceFhirIdGet()` */
  static readonly AdminLocationsSpecialitiesHealthCareServiceFhirIdGetPath = '/Admin/LocationsSpecialities/{healthCareServiceFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLocationsSpecialitiesHealthCareServiceFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminLocationsSpecialitiesHealthCareServiceFhirIdGet$Response(params: AdminLocationsSpecialitiesHealthCareServiceFhirIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<HealthCareServiceMetadataModel>> {
    return adminLocationsSpecialitiesHealthCareServiceFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminLocationsSpecialitiesHealthCareServiceFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminLocationsSpecialitiesHealthCareServiceFhirIdGet(params: AdminLocationsSpecialitiesHealthCareServiceFhirIdGet$Params, context?: HttpContext): Observable<HealthCareServiceMetadataModel> {
    return this.adminLocationsSpecialitiesHealthCareServiceFhirIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<HealthCareServiceMetadataModel>): HealthCareServiceMetadataModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdSpecialitiesGet()` */
  static readonly AdminFhirIdSpecialitiesGetPath = '/Admin/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesGet$Response(params: AdminFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<KeyValueModel>>> {
    return adminFhirIdSpecialitiesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesGet(params: AdminFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<Array<KeyValueModel>> {
    return this.adminFhirIdSpecialitiesGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<KeyValueModel>>): Array<KeyValueModel> => r.body)
    );
  }

}
