/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { cronofyCallbackGet } from '../fn/cronofy/cronofy-callback-get';
import { CronofyCallbackGet$Params } from '../fn/cronofy/cronofy-callback-get';

@Injectable({ providedIn: 'root' })
export class CalendarCronofyService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `cronofyCallbackGet()` */
  static readonly CronofyCallbackGetPath = '/Cronofy/Callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cronofyCallbackGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  cronofyCallbackGet$Response(params?: CronofyCallbackGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<void>> {
    return cronofyCallbackGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cronofyCallbackGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cronofyCallbackGet(params?: CronofyCallbackGet$Params, context?: HttpContext): Observable<void> {
    return this.cronofyCallbackGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<void>): void => r.body)
    );
  }

}
