import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * This is the event service class
 * @deprecated This class is deprecated and event handling should be done in their respective services.
 */
export class EventService {
  public loggedOut: EventEmitter<any> = new EventEmitter<any>();

  public clientAccessToken: EventEmitter<any> = new EventEmitter<any>();

  public removeAccessToken: EventEmitter<boolean> = new EventEmitter<boolean>();

  public healthScoreUpdated: EventEmitter<any> = new EventEmitter<any>();

  public appDeeplinkEvent: EventEmitter<string> = new EventEmitter<string>();

  public appPaused: EventEmitter<any> = new EventEmitter<any>();
  public appResumed: EventEmitter<any> = new EventEmitter<any>();
  public appConfigLoaded: EventEmitter<any> = new EventEmitter<any>();
}
