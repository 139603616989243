/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { CalendarModel } from '../models/calendar-model';
import { consumerCalendarPost } from '../fn/consumer/consumer-calendar-post';
import { ConsumerCalendarPost$Params } from '../fn/consumer/consumer-calendar-post';
import { consumerCalendarsGet } from '../fn/consumer/consumer-calendars-get';
import { ConsumerCalendarsGet$Params } from '../fn/consumer/consumer-calendars-get';
import { consumerLinkCalendarGet } from '../fn/consumer/consumer-link-calendar-get';
import { ConsumerLinkCalendarGet$Params } from '../fn/consumer/consumer-link-calendar-get';

@Injectable({ providedIn: 'root' })
export class CalendarConsumerService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerLinkCalendarGet()` */
  static readonly ConsumerLinkCalendarGetPath = '/Consumer/LinkCalendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerLinkCalendarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerLinkCalendarGet$Response(params?: ConsumerLinkCalendarGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return consumerLinkCalendarGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerLinkCalendarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerLinkCalendarGet(params?: ConsumerLinkCalendarGet$Params, context?: HttpContext): Observable<string> {
    return this.consumerLinkCalendarGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `consumerCalendarsGet()` */
  static readonly ConsumerCalendarsGetPath = '/Consumer/Calendars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerCalendarsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerCalendarsGet$Response(params?: ConsumerCalendarsGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<Array<CalendarModel>>> {
    return consumerCalendarsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerCalendarsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerCalendarsGet(params?: ConsumerCalendarsGet$Params, context?: HttpContext): Observable<Array<CalendarModel>> {
    return this.consumerCalendarsGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<Array<CalendarModel>>): Array<CalendarModel> => r.body)
    );
  }

  /** Path part for operation `consumerCalendarPost()` */
  static readonly ConsumerCalendarPostPath = '/Consumer/Calendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerCalendarPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerCalendarPost$Response(params?: ConsumerCalendarPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<void>> {
    return consumerCalendarPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerCalendarPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerCalendarPost(params?: ConsumerCalendarPost$Params, context?: HttpContext): Observable<void> {
    return this.consumerCalendarPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<void>): void => r.body)
    );
  }

}
