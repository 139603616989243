/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';

import { consumerHealthcareServicePost } from '../fn/consumer/consumer-healthcare-service-post';
import { ConsumerHealthcareServicePost$Params } from '../fn/consumer/consumer-healthcare-service-post';
import { consumerOrganizationsPageSizeGet } from '../fn/consumer/consumer-organizations-page-size-get';
import { ConsumerOrganizationsPageSizeGet$Params } from '../fn/consumer/consumer-organizations-page-size-get';
import { HealthcareServiceConsumerModel } from '../models/healthcare-service-consumer-model';
import { OrganizationSearchResultModel } from '../models/organization-search-result-model';

@Injectable({ providedIn: 'root' })
export class OrganizationConsumerService extends OrganizationBaseService {
  constructor(config: OrganizationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerHealthcareServicePost()` */
  static readonly ConsumerHealthcareServicePostPath = '/Consumer/HealthcareService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerHealthcareServicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerHealthcareServicePost$Response(params?: ConsumerHealthcareServicePost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>> {
    return consumerHealthcareServicePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerHealthcareServicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerHealthcareServicePost(params?: ConsumerHealthcareServicePost$Params, context?: HttpContext): Observable<Array<HealthcareServiceConsumerModel>> {
    return this.consumerHealthcareServicePost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthcareServiceConsumerModel>>): Array<HealthcareServiceConsumerModel> => r.body)
    );
  }

  /** Path part for operation `consumerOrganizationsPageSizeGet()` */
  static readonly ConsumerOrganizationsPageSizeGetPath = '/Consumer/Organizations/{pageSize}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrganizationsPageSizeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationsPageSizeGet$Response(params: ConsumerOrganizationsPageSizeGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>> {
    return consumerOrganizationsPageSizeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerOrganizationsPageSizeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationsPageSizeGet(params: ConsumerOrganizationsPageSizeGet$Params, context?: HttpContext): Observable<Array<OrganizationSearchResultModel>> {
    return this.consumerOrganizationsPageSizeGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>): Array<OrganizationSearchResultModel> => r.body)
    );
  }

}
