import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/dev/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalHttpInterceptorService } from './auth/global-http-interceptor-service ';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MPIModule } from './api/proxy/mpi/mpi.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppEnvironmentModule } from './api/proxy/app-environment/app-environment.module';
import { EnvironmentConfigService } from './services/environment-config-service/environment-config.service';
import { EventService } from './services/events/event.service';
import { MPIApiConfiguration } from './api/proxy/mpi/mpi-api-configuration';
import { CalendarModule } from './api/proxy/calendar/calendar.module';
import { CalendarApiConfiguration } from './api/proxy/calendar/calendar-api-configuration';
import { SplashScreen } from '@capacitor/splash-screen';
import { BookingModule } from './api/proxy/booking/booking.module';
import { BookingApiConfiguration } from './api/proxy/booking/booking-api-configuration';
import { HealthFindrModule } from './api/proxy/healthFindr/health-findr.module';
import { HealthFindrApiConfiguration } from './api/proxy/healthFindr/health-findr-api-configuration';
import { OrganizationApiConfiguration } from './api/proxy/organization/organization-api-configuration';
import { OrganizationModule } from './api/proxy/organization/organization.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function startupServiceFactory(_EnvironmentConfigService: EnvironmentConfigService): Function {
  return async () => {
    await SplashScreen.show({
      autoHide: true,
    });

    await _EnvironmentConfigService.LoadConfigurationAsync();

    await SplashScreen.hide();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AppEnvironmentModule.forRoot({
      rootUrl: environment.appEnvironmentBaseUrl,
    }),
    MPIModule.forRoot({ rootUrl: '' }),
    CalendarModule.forRoot({ rootUrl: '' }),
    BookingModule.forRoot({ rootUrl: '' }),
    OrganizationModule.forRoot({ rootUrl: '' }),
    HealthFindrModule.forRoot({ rootUrl: '' }),
  ],

  providers: [
    EnvironmentConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [EnvironmentConfigService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _EnvironmentConfigService: EnvironmentConfigService,
    private events: EventService,
    private _MPIApiConfiguration: MPIApiConfiguration,
    private _CalendarApiConfiguration: CalendarApiConfiguration,
    private _BookingApiConfiguration: BookingApiConfiguration,
    private _OrganizationConfiguration: OrganizationApiConfiguration,
    private _HealthFindrConfiguration: HealthFindrApiConfiguration
  ) {
    this.events.appConfigLoaded.subscribe((val: any) => {
      var paths = this._EnvironmentConfigService.EnvironmentConfig();
      this._MPIApiConfiguration.rootUrl = paths!.serviceEndpointConfig!.mpi!;
      this._CalendarApiConfiguration.rootUrl = paths!.serviceEndpointConfig!.calendar!;
      this._OrganizationConfiguration.rootUrl = paths!.serviceEndpointConfig!.organization!;
      this._BookingApiConfiguration.rootUrl = paths!.serviceEndpointConfig!.booking!;
      this._HealthFindrConfiguration.rootUrl = paths!.serviceEndpointConfig!.healtHfINDR!;
    });
  }
}
