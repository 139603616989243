/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CalendarStrictHttpResponse } from '../../calendar-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MedicalAdvisorConfirmReservationRequestModel } from '../../models/medical-advisor-confirm-reservation-request-model';

export interface MedicalAdvisorConfirmReservationPost$Params {
      body?: MedicalAdvisorConfirmReservationRequestModel
}

export function medicalAdvisorConfirmReservationPost(http: HttpClient, rootUrl: string, params?: MedicalAdvisorConfirmReservationPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, medicalAdvisorConfirmReservationPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as CalendarStrictHttpResponse<string>;
    })
  );
}

medicalAdvisorConfirmReservationPost.PATH = '/MedicalAdvisor/ConfirmReservation';
