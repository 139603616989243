import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { StorageKey } from './models/storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  async set(key: StorageKey, value: string): Promise<boolean> {
    return await Preferences.set({
      key: key,
      value: value,
    }).then(() => true).catch(() => false);
  }

  async get(key: StorageKey): Promise<string | null> {
    return await Preferences.get({ key: key })
      .then((response) => {
        return response.value;
      }
      ).catch(() => null);
  }

  async remove(key: StorageKey): Promise<boolean> {
    return await Preferences.remove({ key: key })
      .then(() => true).catch(() => false);
  }

  async clear(): Promise<boolean> {
    return await Preferences.clear()
      .then(() => true).catch(() => false);
  }

  async keys(): Promise<Array<StorageKey>> {
    return await Preferences.keys()
      .then((response) => response.keys as StorageKey[])
      .catch(() => []);
  }
}
