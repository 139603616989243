/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { communicationTemplateGetEmailMacrosGet } from '../fn/communication-template/communication-template-get-email-macros-get';
import { CommunicationTemplateGetEmailMacrosGet$Params } from '../fn/communication-template/communication-template-get-email-macros-get';
import { communicationTemplateGetSmsMacrosGet } from '../fn/communication-template/communication-template-get-sms-macros-get';
import { CommunicationTemplateGetSmsMacrosGet$Params } from '../fn/communication-template/communication-template-get-sms-macros-get';
import { communicationTemplateIdDelete } from '../fn/communication-template/communication-template-id-delete';
import { CommunicationTemplateIdDelete$Params } from '../fn/communication-template/communication-template-id-delete';
import { communicationTemplateIdGet } from '../fn/communication-template/communication-template-id-get';
import { CommunicationTemplateIdGet$Params } from '../fn/communication-template/communication-template-id-get';
import { CommunicationTemplateModel } from '../models/communication-template-model';
import { communicationTemplateSavePost } from '../fn/communication-template/communication-template-save-post';
import { CommunicationTemplateSavePost$Params } from '../fn/communication-template/communication-template-save-post';
import { communicationTemplateSearchPost } from '../fn/communication-template/communication-template-search-post';
import { CommunicationTemplateSearchPost$Params } from '../fn/communication-template/communication-template-search-post';
import { CommunicationTemplateSearchResultModel } from '../models/communication-template-search-result-model';

@Injectable({ providedIn: 'root' })
export class HealthFindrCommunicationTemplateService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `communicationTemplateSearchPost()` */
  static readonly CommunicationTemplateSearchPostPath = '/CommunicationTemplate/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSearchPost$Response(params?: CommunicationTemplateSearchPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<CommunicationTemplateSearchResultModel>>> {
    return communicationTemplateSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSearchPost(params?: CommunicationTemplateSearchPost$Params, context?: HttpContext): Observable<Array<CommunicationTemplateSearchResultModel>> {
    return this.communicationTemplateSearchPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<CommunicationTemplateSearchResultModel>>): Array<CommunicationTemplateSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `communicationTemplateIdGet()` */
  static readonly CommunicationTemplateIdGetPath = '/CommunicationTemplate/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdGet$Response(params: CommunicationTemplateIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<CommunicationTemplateModel>> {
    return communicationTemplateIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdGet(params: CommunicationTemplateIdGet$Params, context?: HttpContext): Observable<CommunicationTemplateModel> {
    return this.communicationTemplateIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<CommunicationTemplateModel>): CommunicationTemplateModel => r.body)
    );
  }

  /** Path part for operation `communicationTemplateIdDelete()` */
  static readonly CommunicationTemplateIdDeletePath = '/CommunicationTemplate/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdDelete$Response(params: CommunicationTemplateIdDelete$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<void>> {
    return communicationTemplateIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateIdDelete(params: CommunicationTemplateIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.communicationTemplateIdDelete$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `communicationTemplateSavePost()` */
  static readonly CommunicationTemplateSavePostPath = '/CommunicationTemplate/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSavePost$Response(params?: CommunicationTemplateSavePost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return communicationTemplateSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  communicationTemplateSavePost(params?: CommunicationTemplateSavePost$Params, context?: HttpContext): Observable<string> {
    return this.communicationTemplateSavePost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `communicationTemplateGetEmailMacrosGet()` */
  static readonly CommunicationTemplateGetEmailMacrosGetPath = '/CommunicationTemplate/GetEmailMacros';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateGetEmailMacrosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateGetEmailMacrosGet$Response(params?: CommunicationTemplateGetEmailMacrosGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<string>>> {
    return communicationTemplateGetEmailMacrosGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateGetEmailMacrosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateGetEmailMacrosGet(params?: CommunicationTemplateGetEmailMacrosGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.communicationTemplateGetEmailMacrosGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `communicationTemplateGetSmsMacrosGet()` */
  static readonly CommunicationTemplateGetSmsMacrosGetPath = '/CommunicationTemplate/GetSmsMacros';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `communicationTemplateGetSmsMacrosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateGetSmsMacrosGet$Response(params?: CommunicationTemplateGetSmsMacrosGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<string>>> {
    return communicationTemplateGetSmsMacrosGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `communicationTemplateGetSmsMacrosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  communicationTemplateGetSmsMacrosGet(params?: CommunicationTemplateGetSmsMacrosGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.communicationTemplateGetSmsMacrosGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
