import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ThemeService } from '../theme/theme.service';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { LoadingService } from '../loading-service/loading.service';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesStartupService {
  private _initialized = false;
  private mainServicesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userServicesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Observable for when main services are loaded
   */
  public mainServicesLoaded$: Observable<boolean> = this.mainServicesLoaded.asObservable();

  /**
   * Observable for when company services are loaded
   */
  public userServicesLoaded$: Observable<boolean> = this.userServicesLoaded.asObservable();
  crashReport: any;

  constructor(
    public alertController: AlertController,
    private themeService: ThemeService,
    private _EnvironmentConfigService: EnvironmentConfigService
  ) {}

  /**
   * Registers needed Subscribers and kicks of the initialization of the apps services
   */
  public async initializeAsync(forceToken: boolean): Promise<void> {
    this.registerSubscribers();
    await this.initializeCoreServicesAsync(forceToken);
  }

  /**
   * Subscribers used to trigger the different initialization steps of this service.
   */
  private registerSubscribers(): void {}

  /**
   * This are there core services needed for the application to startup
   * and should only contain services that are needed asap or to startup
   * services that are needed by the main initialization process.
   */
  private async initializeCoreServicesAsync(forceToken: boolean): Promise<void> {
    if (this._initialized) return;

    this._initialized = true;

    if (Capacitor.getPlatform() != 'web') {
      await FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      });
    }

    await this.initServices(forceToken);
  }

  public async initServices(forceToken: boolean) {
    await this._EnvironmentConfigService.LoadConfigurationAsync();

    var force = forceToken && Capacitor.getPlatform() != 'web';

    await this.themeService.initializeAsync();
  }

  /**
   * These are the main services needed to startup before the app is allowed
   * to route into itself and usually contain services that can be started without
   * the need for user data.
   */
  private async initializeMainServicesAsync(): Promise<void> {
    this.mainServicesLoaded.next(true);
  }

  /**
   * The final initialization of services that are used by different parts
   * of the app and are generally loaded by environment determined by the
   * logged in user.
   */
  private async initializeUserServicesAsync(): Promise<void> {
    this.userServicesLoaded.next(true);
  }
}
