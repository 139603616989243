/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CalendarStrictHttpResponse } from '../../calendar-strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface HealthFindrRequestSessionGet$Params {
  accountId?: string;
  parentEntityId?: string;
  firstName?: string;
  surname?: string;
  mobileNumber?: string;
  emailAddress?: string;
  dateOfBirth?: string;
  brokerEmail?: string;
  caseNumber?: string;
  identificationNumber?: string;
  bookingReasonId?: string;
}

export function healthFindrRequestSessionGet(http: HttpClient, rootUrl: string, params?: HealthFindrRequestSessionGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, healthFindrRequestSessionGet.PATH, 'get');
  if (params) {
    rb.query('accountId', params.accountId, {});
    rb.query('parentEntityId', params.parentEntityId, {});
    rb.query('firstName', params.firstName, {});
    rb.query('surname', params.surname, {});
    rb.query('mobileNumber', params.mobileNumber, {});
    rb.query('emailAddress', params.emailAddress, {});
    rb.query('dateOfBirth', params.dateOfBirth, {});
    rb.query('brokerEmail', params.brokerEmail, {});
    rb.query('caseNumber', params.caseNumber, {});
    rb.query('identificationNumber', params.identificationNumber, {});
    rb.query('bookingReasonId', params.bookingReasonId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as CalendarStrictHttpResponse<void>;
    })
  );
}

healthFindrRequestSessionGet.PATH = '/HealthFindr/RequestSession';
