/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';

import { adminPersonIdDelete } from '../fn/admin/admin-person-id-delete';
import { AdminPersonIdDelete$Params } from '../fn/admin/admin-person-id-delete';
import { adminPersonIdGet } from '../fn/admin/admin-person-id-get';
import { AdminPersonIdGet$Params } from '../fn/admin/admin-person-id-get';
import { adminPersonIdPhotoPost } from '../fn/admin/admin-person-id-photo-post';
import { AdminPersonIdPhotoPost$Params } from '../fn/admin/admin-person-id-photo-post';
import { adminPost } from '../fn/admin/admin-post';
import { AdminPost$Params } from '../fn/admin/admin-post';
import { adminSearchPost } from '../fn/admin/admin-search-post';
import { AdminSearchPost$Params } from '../fn/admin/admin-search-post';
import { PatientAdminModel } from '../models/patient-admin-model';
import { PatientSearchResultModel } from '../models/patient-search-result-model';

@Injectable({ providedIn: 'root' })
export class MPIAdminService extends MPIBaseService {
  constructor(config: MPIApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminSearchPost()` */
  static readonly AdminSearchPostPath = '/Admin/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Response(params?: AdminSearchPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PatientSearchResultModel>>> {
    return adminSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost(params?: AdminSearchPost$Params, context?: HttpContext): Observable<Array<PatientSearchResultModel>> {
    return this.adminSearchPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PatientSearchResultModel>>): Array<PatientSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminPersonIdGet()` */
  static readonly AdminPersonIdGetPath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Response(params: AdminPersonIdGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PatientAdminModel>> {
    return adminPersonIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet(params: AdminPersonIdGet$Params, context?: HttpContext): Observable<PatientAdminModel> {
    return this.adminPersonIdGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PatientAdminModel>): PatientAdminModel => r.body)
    );
  }

  /** Path part for operation `adminPersonIdDelete()` */
  static readonly AdminPersonIdDeletePath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete$Response(params: AdminPersonIdDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return adminPersonIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete(params: AdminPersonIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminPersonIdDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminPost()` */
  static readonly AdminPostPath = '/Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Response(params?: AdminPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost(params?: AdminPost$Params, context?: HttpContext): Observable<string> {
    return this.adminPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminPersonIdPhotoPost()` */
  static readonly AdminPersonIdPhotoPostPath = '/Admin/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdPhotoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Response(params: AdminPersonIdPhotoPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminPersonIdPhotoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdPhotoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost(params: AdminPersonIdPhotoPost$Params, context?: HttpContext): Observable<string> {
    return this.adminPersonIdPhotoPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

}
