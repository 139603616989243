/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { serviceConfigurationIdDelete } from '../fn/service-configuration/service-configuration-id-delete';
import { ServiceConfigurationIdDelete$Params } from '../fn/service-configuration/service-configuration-id-delete';
import { serviceConfigurationIdGet } from '../fn/service-configuration/service-configuration-id-get';
import { ServiceConfigurationIdGet$Params } from '../fn/service-configuration/service-configuration-id-get';
import { ServiceConfigurationModel } from '../models/service-configuration-model';
import { serviceConfigurationSavePost } from '../fn/service-configuration/service-configuration-save-post';
import { ServiceConfigurationSavePost$Params } from '../fn/service-configuration/service-configuration-save-post';
import { serviceConfigurationSearchPost } from '../fn/service-configuration/service-configuration-search-post';
import { ServiceConfigurationSearchPost$Params } from '../fn/service-configuration/service-configuration-search-post';
import { ServiceConfigurationSearchResultModel } from '../models/service-configuration-search-result-model';

@Injectable({ providedIn: 'root' })
export class HealthFindrServiceConfigurationService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `serviceConfigurationSearchPost()` */
  static readonly ServiceConfigurationSearchPostPath = '/ServiceConfiguration/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSearchPost$Response(params?: ServiceConfigurationSearchPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<ServiceConfigurationSearchResultModel>>> {
    return serviceConfigurationSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSearchPost(params?: ServiceConfigurationSearchPost$Params, context?: HttpContext): Observable<Array<ServiceConfigurationSearchResultModel>> {
    return this.serviceConfigurationSearchPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<ServiceConfigurationSearchResultModel>>): Array<ServiceConfigurationSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `serviceConfigurationIdGet()` */
  static readonly ServiceConfigurationIdGetPath = '/ServiceConfiguration/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdGet$Response(params: ServiceConfigurationIdGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<ServiceConfigurationModel>> {
    return serviceConfigurationIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdGet(params: ServiceConfigurationIdGet$Params, context?: HttpContext): Observable<ServiceConfigurationModel> {
    return this.serviceConfigurationIdGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<ServiceConfigurationModel>): ServiceConfigurationModel => r.body)
    );
  }

  /** Path part for operation `serviceConfigurationIdDelete()` */
  static readonly ServiceConfigurationIdDeletePath = '/ServiceConfiguration/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdDelete$Response(params: ServiceConfigurationIdDelete$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<void>> {
    return serviceConfigurationIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceConfigurationIdDelete(params: ServiceConfigurationIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.serviceConfigurationIdDelete$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `serviceConfigurationSavePost()` */
  static readonly ServiceConfigurationSavePostPath = '/ServiceConfiguration/Save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceConfigurationSavePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSavePost$Response(params?: ServiceConfigurationSavePost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<string>> {
    return serviceConfigurationSavePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceConfigurationSavePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceConfigurationSavePost(params?: ServiceConfigurationSavePost$Params, context?: HttpContext): Observable<string> {
    return this.serviceConfigurationSavePost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<string>): string => r.body)
    );
  }

}
