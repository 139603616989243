/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarApiConfiguration, CalendarApiConfigurationParams } from './calendar-api-configuration';

import { CalendarConsumerService } from './services/calendar-consumer.service';
import { CalendarCronofyService } from './services/calendar-cronofy.service';
import { CalendarDiaryService } from './services/calendar-diary.service';
import { CalendarHealthFindrService } from './services/calendar-health-findr.service';
import { CalendarMedicalAdvisorService } from './services/calendar-medical-advisor.service';
import { CalendarPatientOnboardingService } from './services/calendar-patient-onboarding.service';
import { CalendarPractitionerFindrService } from './services/calendar-practitioner-findr.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CalendarConsumerService,
    CalendarCronofyService,
    CalendarDiaryService,
    CalendarHealthFindrService,
    CalendarMedicalAdvisorService,
    CalendarPatientOnboardingService,
    CalendarPractitionerFindrService,
    CalendarApiConfiguration
  ],
})
export class CalendarModule {
  static forRoot(params: CalendarApiConfigurationParams): ModuleWithProviders<CalendarModule> {
    return {
      ngModule: CalendarModule,
      providers: [
        {
          provide: CalendarApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CalendarModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CalendarModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
