import { WebPlugin, registerPlugin } from '@capacitor/core';

export interface DeviceCountryPlugin {
    getCurrentCountry(): Promise<{ country: string, device: string }>;
}

const DeviceCountry = registerPlugin<DeviceCountryPlugin>('DeviceCountryPlugin', {
    web: () => new DeviceCountryWeb(),
});
export default DeviceCountry;


export class DeviceCountryWeb extends WebPlugin implements DeviceCountryPlugin {
    async getCurrentCountry() {

        var tx = Intl.DateTimeFormat().resolvedOptions();
        var country = tx.timeZone;

        if(!country){
            country = tx.locale;
            if(country && country.indexOf('-')){
                country = country.split('-')[1];
            }
        }

        var found = false;
        const params = new URLSearchParams(window.location.search);
        if (params && params.has('r')) {
            const r = params.get('r');
            if (r) {
                country = r;
                found = true;
            }
        }

        if (!found) {
            var location = window.location.hostname;
            var loctionSplit = location.split('.');
            if (loctionSplit.length >= 4) {
                country = loctionSplit[1];
                found = true;
            }
        }
        return {
            country: country,
            device: 'web'
        };
    }



}