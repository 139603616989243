/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BookingStrictHttpResponse } from '../../booking-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CancelBookingRequest } from '../../models/cancel-booking-request';

export interface HealthFindrCancelBookingPost$Params {
      body?: CancelBookingRequest
}

export function healthFindrCancelBookingPost(http: HttpClient, rootUrl: string, params?: HealthFindrCancelBookingPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, healthFindrCancelBookingPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as BookingStrictHttpResponse<void>;
    })
  );
}

healthFindrCancelBookingPost.PATH = '/HealthFindr/CancelBooking';
