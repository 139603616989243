/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { diaryAvailibilityPost } from '../fn/diary/diary-availibility-post';
import { DiaryAvailibilityPost$Params } from '../fn/diary/diary-availibility-post';
import { diaryBatchAvailibilityPost } from '../fn/diary/diary-batch-availibility-post';
import { DiaryBatchAvailibilityPost$Params } from '../fn/diary/diary-batch-availibility-post';
import { UrnAvailabilityDayModelAvailibilityModel } from '../models/urn-availability-day-model-availibility-model';

@Injectable({ providedIn: 'root' })
export class CalendarDiaryService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `diaryAvailibilityPost()` */
  static readonly DiaryAvailibilityPostPath = '/Diary/Availibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  diaryAvailibilityPost$Response(params?: DiaryAvailibilityPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>> {
    return diaryAvailibilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  diaryAvailibilityPost(params?: DiaryAvailibilityPost$Params, context?: HttpContext): Observable<UrnAvailabilityDayModelAvailibilityModel> {
    return this.diaryAvailibilityPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>): UrnAvailabilityDayModelAvailibilityModel => r.body)
    );
  }

  /** Path part for operation `diaryBatchAvailibilityPost()` */
  static readonly DiaryBatchAvailibilityPostPath = '/Diary/BatchAvailibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `diaryBatchAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  diaryBatchAvailibilityPost$Response(params?: DiaryBatchAvailibilityPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<{
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
}>> {
    return diaryBatchAvailibilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `diaryBatchAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  diaryBatchAvailibilityPost(params?: DiaryBatchAvailibilityPost$Params, context?: HttpContext): Observable<{
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
}> {
    return this.diaryBatchAvailibilityPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<{
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
}>): {
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
} => r.body)
    );
  }

}
