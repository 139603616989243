/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HealthFindrStrictHttpResponse } from '../../health-findr-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PromotionalLinkModel } from '../../models/promotional-link-model';

export interface PromotionalLinkFhirIdPromotionalLinksGet$Params {
  fhirId: string;
}

export function promotionalLinkFhirIdPromotionalLinksGet(http: HttpClient, rootUrl: string, params: PromotionalLinkFhirIdPromotionalLinksGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>> {
  const rb = new RequestBuilder(rootUrl, promotionalLinkFhirIdPromotionalLinksGet.PATH, 'get');
  if (params) {
    rb.path('fhirId', params.fhirId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>;
    })
  );
}

promotionalLinkFhirIdPromotionalLinksGet.PATH = '/PromotionalLink/{fhirId}/PromotionalLinks';
