/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface AnonymousFhirIdPageTitleGet$Params {
  fhirId: string;
}

export function anonymousFhirIdPageTitleGet(
  http: HttpClient,
  rootUrl: string,
  params: AnonymousFhirIdPageTitleGet$Params,
  context?: HttpContext
): Observable<OrganizationStrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, anonymousFhirIdPageTitleGet.PATH, 'get');
  if (params) {
    rb.path('fhirId', params.fhirId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<string>;
    })
  );
}

anonymousFhirIdPageTitleGet.PATH = '/Anonymous/{fhirId}/pageTitle';
