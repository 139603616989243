/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BookingBaseService } from '../booking-base-service';
import { BookingApiConfiguration } from '../booking-api-configuration';
import { BookingStrictHttpResponse } from '../booking-strict-http-response';

import { BookingDetailModel } from '../models/booking-detail-model';
import { HealthCareServiceModel } from '../models/health-care-service-model';
import { healthFindrAvailibilityPost } from '../fn/health-findr/health-findr-availibility-post';
import { HealthFindrAvailibilityPost$Params } from '../fn/health-findr/health-findr-availibility-post';
import { healthFindrBatchAvailibilityPost } from '../fn/health-findr/health-findr-batch-availibility-post';
import { HealthFindrBatchAvailibilityPost$Params } from '../fn/health-findr/health-findr-batch-availibility-post';
import { healthFindrBookingDetailUrnBookingIdGet } from '../fn/health-findr/health-findr-booking-detail-urn-booking-id-get';
import { HealthFindrBookingDetailUrnBookingIdGet$Params } from '../fn/health-findr/health-findr-booking-detail-urn-booking-id-get';
import { healthFindrCancelBookingPost } from '../fn/health-findr/health-findr-cancel-booking-post';
import { HealthFindrCancelBookingPost$Params } from '../fn/health-findr/health-findr-cancel-booking-post';
import { healthFindrConfirmBookingPost } from '../fn/health-findr/health-findr-confirm-booking-post';
import { HealthFindrConfirmBookingPost$Params } from '../fn/health-findr/health-findr-confirm-booking-post';
import { healthFindrConfirmReservationPost } from '../fn/health-findr/health-findr-confirm-reservation-post';
import { HealthFindrConfirmReservationPost$Params } from '../fn/health-findr/health-findr-confirm-reservation-post';
import { healthFindrFhirIdSpecialitiesGet } from '../fn/health-findr/health-findr-fhir-id-specialities-get';
import { HealthFindrFhirIdSpecialitiesGet$Params } from '../fn/health-findr/health-findr-fhir-id-specialities-get';
import { healthFindrHealthCareServicesPost } from '../fn/health-findr/health-findr-health-care-services-post';
import { HealthFindrHealthCareServicesPost$Params } from '../fn/health-findr/health-findr-health-care-services-post';
import { healthFindrHealthCareServicesRadiusPost } from '../fn/health-findr/health-findr-health-care-services-radius-post';
import { HealthFindrHealthCareServicesRadiusPost$Params } from '../fn/health-findr/health-findr-health-care-services-radius-post';
import { healthFindrRequestReservationPost } from '../fn/health-findr/health-findr-request-reservation-post';
import { HealthFindrRequestReservationPost$Params } from '../fn/health-findr/health-findr-request-reservation-post';
import { SpecialityResultModel } from '../models/speciality-result-model';
import { UrnAvailabilityDayModelAvailibilityModel } from '../models/urn-availability-day-model-availibility-model';

@Injectable({ providedIn: 'root' })
export class BookingHealthFindrService extends BookingBaseService {
  constructor(config: BookingApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `healthFindrFhirIdSpecialitiesGet()` */
  static readonly HealthFindrFhirIdSpecialitiesGetPath = '/HealthFindr/{fhirId}/Specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrFhirIdSpecialitiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrFhirIdSpecialitiesGet$Response(params: HealthFindrFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<Array<SpecialityResultModel>>> {
    return healthFindrFhirIdSpecialitiesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrFhirIdSpecialitiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrFhirIdSpecialitiesGet(params: HealthFindrFhirIdSpecialitiesGet$Params, context?: HttpContext): Observable<Array<SpecialityResultModel>> {
    return this.healthFindrFhirIdSpecialitiesGet$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<Array<SpecialityResultModel>>): Array<SpecialityResultModel> => r.body)
    );
  }

  /** Path part for operation `healthFindrHealthCareServicesPost()` */
  static readonly HealthFindrHealthCareServicesPostPath = '/HealthFindr/HealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesPost$Response(params?: HealthFindrHealthCareServicesPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<Array<HealthCareServiceModel>>> {
    return healthFindrHealthCareServicesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesPost(params?: HealthFindrHealthCareServicesPost$Params, context?: HttpContext): Observable<Array<HealthCareServiceModel>> {
    return this.healthFindrHealthCareServicesPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<Array<HealthCareServiceModel>>): Array<HealthCareServiceModel> => r.body)
    );
  }

  /** Path part for operation `healthFindrHealthCareServicesRadiusPost()` */
  static readonly HealthFindrHealthCareServicesRadiusPostPath = '/HealthFindr/HealthCareServicesRadius';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrHealthCareServicesRadiusPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesRadiusPost$Response(params?: HealthFindrHealthCareServicesRadiusPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<Array<HealthCareServiceModel>>> {
    return healthFindrHealthCareServicesRadiusPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrHealthCareServicesRadiusPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrHealthCareServicesRadiusPost(params?: HealthFindrHealthCareServicesRadiusPost$Params, context?: HttpContext): Observable<Array<HealthCareServiceModel>> {
    return this.healthFindrHealthCareServicesRadiusPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<Array<HealthCareServiceModel>>): Array<HealthCareServiceModel> => r.body)
    );
  }

  /** Path part for operation `healthFindrAvailibilityPost()` */
  static readonly HealthFindrAvailibilityPostPath = '/HealthFindr/Availibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrAvailibilityPost$Response(params?: HealthFindrAvailibilityPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>> {
    return healthFindrAvailibilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrAvailibilityPost(params?: HealthFindrAvailibilityPost$Params, context?: HttpContext): Observable<UrnAvailabilityDayModelAvailibilityModel> {
    return this.healthFindrAvailibilityPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<UrnAvailabilityDayModelAvailibilityModel>): UrnAvailabilityDayModelAvailibilityModel => r.body)
    );
  }

  /** Path part for operation `healthFindrBatchAvailibilityPost()` */
  static readonly HealthFindrBatchAvailibilityPostPath = '/HealthFindr/BatchAvailibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrBatchAvailibilityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrBatchAvailibilityPost$Response(params?: HealthFindrBatchAvailibilityPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<{
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
}>> {
    return healthFindrBatchAvailibilityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrBatchAvailibilityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrBatchAvailibilityPost(params?: HealthFindrBatchAvailibilityPost$Params, context?: HttpContext): Observable<{
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
}> {
    return this.healthFindrBatchAvailibilityPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<{
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
}>): {
[key: string]: UrnAvailabilityDayModelAvailibilityModel;
} => r.body)
    );
  }

  /** Path part for operation `healthFindrRequestReservationPost()` */
  static readonly HealthFindrRequestReservationPostPath = '/HealthFindr/RequestReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrRequestReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestReservationPost$Response(params?: HealthFindrRequestReservationPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<string>> {
    return healthFindrRequestReservationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrRequestReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrRequestReservationPost(params?: HealthFindrRequestReservationPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrRequestReservationPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrConfirmReservationPost()` */
  static readonly HealthFindrConfirmReservationPostPath = '/HealthFindr/ConfirmReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrConfirmReservationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmReservationPost$Response(params?: HealthFindrConfirmReservationPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<string>> {
    return healthFindrConfirmReservationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrConfirmReservationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmReservationPost(params?: HealthFindrConfirmReservationPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrConfirmReservationPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrConfirmBookingPost()` */
  static readonly HealthFindrConfirmBookingPostPath = '/HealthFindr/ConfirmBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrConfirmBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmBookingPost$Response(params?: HealthFindrConfirmBookingPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<string>> {
    return healthFindrConfirmBookingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrConfirmBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrConfirmBookingPost(params?: HealthFindrConfirmBookingPost$Params, context?: HttpContext): Observable<string> {
    return this.healthFindrConfirmBookingPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `healthFindrBookingDetailUrnBookingIdGet()` */
  static readonly HealthFindrBookingDetailUrnBookingIdGetPath = '/HealthFindr/BookingDetail/{urnBookingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrBookingDetailUrnBookingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrBookingDetailUrnBookingIdGet$Response(params: HealthFindrBookingDetailUrnBookingIdGet$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<BookingDetailModel>> {
    return healthFindrBookingDetailUrnBookingIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrBookingDetailUrnBookingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthFindrBookingDetailUrnBookingIdGet(params: HealthFindrBookingDetailUrnBookingIdGet$Params, context?: HttpContext): Observable<BookingDetailModel> {
    return this.healthFindrBookingDetailUrnBookingIdGet$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<BookingDetailModel>): BookingDetailModel => r.body)
    );
  }

  /** Path part for operation `healthFindrCancelBookingPost()` */
  static readonly HealthFindrCancelBookingPostPath = '/HealthFindr/CancelBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthFindrCancelBookingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrCancelBookingPost$Response(params?: HealthFindrCancelBookingPost$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<void>> {
    return healthFindrCancelBookingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthFindrCancelBookingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  healthFindrCancelBookingPost(params?: HealthFindrCancelBookingPost$Params, context?: HttpContext): Observable<void> {
    return this.healthFindrCancelBookingPost$Response(params, context).pipe(
      map((r: BookingStrictHttpResponse<void>): void => r.body)
    );
  }

}
