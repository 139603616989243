/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';

import { anonymousFhirIdBackgroundImageGet } from '../fn/anonymous/anonymous-fhir-id-background-image-get';
import { AnonymousFhirIdBackgroundImageGet$Params } from '../fn/anonymous/anonymous-fhir-id-background-image-get';
import { anonymousFhirIdImageGet } from '../fn/anonymous/anonymous-fhir-id-image-get';
import { AnonymousFhirIdImageGet$Params } from '../fn/anonymous/anonymous-fhir-id-image-get';
import { anonymousFhirIdPageTitleGet } from '../fn/anonymous/anonymous-fhir-id-page-title-get';
import { AnonymousFhirIdPageTitleGet$Params } from '../fn/anonymous/anonymous-fhir-id-page-title-get';
import { anonymousHostnameForParentEntityIdGet } from '../fn/anonymous/anonymous-hostname-for-parent-entity-id-get';
import { AnonymousHostnameForParentEntityIdGet$Params } from '../fn/anonymous/anonymous-hostname-for-parent-entity-id-get';
import { anonymousOrganizationConfigurationGet } from '../fn/anonymous/anonymous-organization-configuration-get';
import { AnonymousOrganizationConfigurationGet$Params } from '../fn/anonymous/anonymous-organization-configuration-get';
import { anonymousParentEntityIdForHostnameGet } from '../fn/anonymous/anonymous-parent-entity-id-for-hostname-get';
import { AnonymousParentEntityIdForHostnameGet$Params } from '../fn/anonymous/anonymous-parent-entity-id-for-hostname-get';
import { anonymousSpecialityIdGet } from '../fn/anonymous/anonymous-speciality-id-get';
import { AnonymousSpecialityIdGet$Params } from '../fn/anonymous/anonymous-speciality-id-get';
import { OrganizationConfigurationModel } from '../models/organization-configuration-model';
import { SpecialityModel } from '../models/speciality-model';

@Injectable({ providedIn: 'root' })
export class OrganizationAnonymousService extends OrganizationBaseService {
  constructor(config: OrganizationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `anonymousSpecialityIdGet()` */
  static readonly AnonymousSpecialityIdGetPath = '/Anonymous/Speciality/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousSpecialityIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityIdGet$Response(params: AnonymousSpecialityIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<SpecialityModel>> {
    return anonymousSpecialityIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousSpecialityIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousSpecialityIdGet(params: AnonymousSpecialityIdGet$Params, context?: HttpContext): Observable<SpecialityModel> {
    return this.anonymousSpecialityIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<SpecialityModel>): SpecialityModel => r.body)
    );
  }

  /** Path part for operation `anonymousParentEntityIdForHostnameGet()` */
  static readonly AnonymousParentEntityIdForHostnameGetPath = '/Anonymous/ParentEntityIdForHostname';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousParentEntityIdForHostnameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousParentEntityIdForHostnameGet$Response(params?: AnonymousParentEntityIdForHostnameGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return anonymousParentEntityIdForHostnameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousParentEntityIdForHostnameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousParentEntityIdForHostnameGet(params?: AnonymousParentEntityIdForHostnameGet$Params, context?: HttpContext): Observable<string> {
    return this.anonymousParentEntityIdForHostnameGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `anonymousHostnameForParentEntityIdGet()` */
  static readonly AnonymousHostnameForParentEntityIdGetPath = '/Anonymous/HostnameForParentEntityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousHostnameForParentEntityIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousHostnameForParentEntityIdGet$Response(params?: AnonymousHostnameForParentEntityIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return anonymousHostnameForParentEntityIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousHostnameForParentEntityIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousHostnameForParentEntityIdGet(params?: AnonymousHostnameForParentEntityIdGet$Params, context?: HttpContext): Observable<string> {
    return this.anonymousHostnameForParentEntityIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `anonymousOrganizationConfigurationGet()` */
  static readonly AnonymousOrganizationConfigurationGetPath = '/Anonymous/OrganizationConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousOrganizationConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousOrganizationConfigurationGet$Response(params?: AnonymousOrganizationConfigurationGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationConfigurationModel>> {
    return anonymousOrganizationConfigurationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousOrganizationConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousOrganizationConfigurationGet(params?: AnonymousOrganizationConfigurationGet$Params, context?: HttpContext): Observable<OrganizationConfigurationModel> {
    return this.anonymousOrganizationConfigurationGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationConfigurationModel>): OrganizationConfigurationModel => r.body)
    );
  }

  /** Path part for operation `anonymousFhirIdImageGet()` */
  static readonly AnonymousFhirIdImageGetPath = '/Anonymous/{fhirId}/Image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFhirIdImageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdImageGet$Response(params: AnonymousFhirIdImageGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return anonymousFhirIdImageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousFhirIdImageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdImageGet(params: AnonymousFhirIdImageGet$Params, context?: HttpContext): Observable<void> {
    return this.anonymousFhirIdImageGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `anonymousFhirIdBackgroundImageGet()` */
  static readonly AnonymousFhirIdBackgroundImageGetPath = '/Anonymous/{fhirId}/backgroundImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFhirIdBackgroundImageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdBackgroundImageGet$Response(params: AnonymousFhirIdBackgroundImageGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return anonymousFhirIdBackgroundImageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousFhirIdBackgroundImageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdBackgroundImageGet(params: AnonymousFhirIdBackgroundImageGet$Params, context?: HttpContext): Observable<void> {
    return this.anonymousFhirIdBackgroundImageGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `anonymousFhirIdPageTitleGet()` */
  static readonly AnonymousFhirIdPageTitleGetPath = '/Anonymous/{fhirId}/pageTitle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anonymousFhirIdPageTitleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdPageTitleGet$Response(params: AnonymousFhirIdPageTitleGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return anonymousFhirIdPageTitleGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anonymousFhirIdPageTitleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anonymousFhirIdPageTitleGet(params: AnonymousFhirIdPageTitleGet$Params, context?: HttpContext): Observable<string> {
    return this.anonymousFhirIdPageTitleGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

}
