/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthFindrBaseService } from '../health-findr-base-service';
import { HealthFindrApiConfiguration } from '../health-findr-api-configuration';
import { HealthFindrStrictHttpResponse } from '../health-findr-strict-http-response';

import { promotionalLinkFhirIdPromotionalLinksGet } from '../fn/promotional-link/promotional-link-fhir-id-promotional-links-get';
import { PromotionalLinkFhirIdPromotionalLinksGet$Params } from '../fn/promotional-link/promotional-link-fhir-id-promotional-links-get';
import { PromotionalLinkModel } from '../models/promotional-link-model';
import { promotionalLinkPromotionalLinkPost } from '../fn/promotional-link/promotional-link-promotional-link-post';
import { PromotionalLinkPromotionalLinkPost$Params } from '../fn/promotional-link/promotional-link-promotional-link-post';

@Injectable({ providedIn: 'root' })
export class HealthFindrPromotionalLinkService extends HealthFindrBaseService {
  constructor(config: HealthFindrApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `promotionalLinkPromotionalLinkPost()` */
  static readonly PromotionalLinkPromotionalLinkPostPath = '/PromotionalLink/PromotionalLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionalLinkPromotionalLinkPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotionalLinkPromotionalLinkPost$Response(params?: PromotionalLinkPromotionalLinkPost$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<PromotionalLinkModel>> {
    return promotionalLinkPromotionalLinkPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promotionalLinkPromotionalLinkPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotionalLinkPromotionalLinkPost(params?: PromotionalLinkPromotionalLinkPost$Params, context?: HttpContext): Observable<PromotionalLinkModel> {
    return this.promotionalLinkPromotionalLinkPost$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<PromotionalLinkModel>): PromotionalLinkModel => r.body)
    );
  }

  /** Path part for operation `promotionalLinkFhirIdPromotionalLinksGet()` */
  static readonly PromotionalLinkFhirIdPromotionalLinksGetPath = '/PromotionalLink/{fhirId}/PromotionalLinks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionalLinkFhirIdPromotionalLinksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionalLinkFhirIdPromotionalLinksGet$Response(params: PromotionalLinkFhirIdPromotionalLinksGet$Params, context?: HttpContext): Observable<HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>> {
    return promotionalLinkFhirIdPromotionalLinksGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promotionalLinkFhirIdPromotionalLinksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionalLinkFhirIdPromotionalLinksGet(params: PromotionalLinkFhirIdPromotionalLinksGet$Params, context?: HttpContext): Observable<Array<PromotionalLinkModel>> {
    return this.promotionalLinkFhirIdPromotionalLinksGet$Response(params, context).pipe(
      map((r: HealthFindrStrictHttpResponse<Array<PromotionalLinkModel>>): Array<PromotionalLinkModel> => r.body)
    );
  }

}
