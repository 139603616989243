import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { from, lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NavController } from '@ionic/angular';
import { LoadingService } from '../services/loading-service/loading.service';
import { EnvironmentConfigService } from '../services/environment-config-service/environment-config.service';
import { AppEnvironmentAppEnvironmentService } from '../api/proxy/app-environment/app-environment-services';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    private _loading: LoadingService,
    public navCtrl: NavController,
    public _EnvironmentConfigService: EnvironmentConfigService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/AppLogMessage')) {
      return next.handle(request);
    }

    if (request.url.includes(AppEnvironmentAppEnvironmentService.AppEnvironmentSettingsPostPath)) {
      return next.handle(request);
    }

    var promise = this.handle(request, next);
    var req = from(promise).pipe(
      catchError((error) => {
        this._loading.setLoading(false, request.url);
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              //error message = error.error
              case 200: //Ok
                console.log(error);
                break;
              case 400: //Bad Request
                break;
              case 401: //login
                break;
              case 403: //forbidden
                break;
              case 404: //not found
                break;
              case 500: //internal server error
                break;
              case 504: //gateway timeout
                break;
              case 501: //not implemented
                break;
              default: //general
                break;
            }
          }
        } else {
          console.error('Other Errors');
        }
        this._loading.setLoading(false, request.url);
        return of(error);
      })
    );
    return req;
  }

  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<unknown>> {
    await this._EnvironmentConfigService.LoadConfigurationAsync();
    const deviceConfig = this._EnvironmentConfigService.DeviceConfig();
    const environmentConfig = this._EnvironmentConfigService.EnvironmentConfig();

    request = request.clone({
      headers: request.headers.set('user-language', this.document.documentElement.lang).set('ngrok-skip-browser-warning', 'true'),
    });

    if (deviceConfig != null && environmentConfig != null) {
      request = request.clone({
        headers: request.headers
          .set('ion-app-deviceType', deviceConfig.deviceType!)
          .set('ion-app-name', deviceConfig.name!)
          .set('ion-app-id', deviceConfig.id!)
          .set('ion-app-version', deviceConfig.version!)
          .set('ion-app-build', deviceConfig.build!)
          .set('ion-app-countryCode', environmentConfig.country!),
      });
    }

    var excludeToken = false;
    var config = this._EnvironmentConfigService.EnvironmentConfigOrEmpty();
    if (config) {
      if (request.url.includes(config.faceScanConfig!.faceScanBase!)) {
        excludeToken = true;
      }
    }

    await this._loading.setLoading(true, request.url);

    const data = await lastValueFrom(
      next.handle(request).pipe(
        map<HttpEvent<any>, any>(async (evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            await this._loading.setLoading(false, request.url);
          }
          return evt;
        })
      )
    );

    return data;
  }
}
