/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarBaseService } from '../calendar-base-service';
import { CalendarApiConfiguration } from '../calendar-api-configuration';
import { CalendarStrictHttpResponse } from '../calendar-strict-http-response';

import { patientOnboardingIdGet } from '../fn/patient-onboarding/patient-onboarding-id-get';
import { PatientOnboardingIdGet$Params } from '../fn/patient-onboarding/patient-onboarding-id-get';
import { PatientOnboardingModel } from '../models/patient-onboarding-model';
import { patientOnboardingPost } from '../fn/patient-onboarding/patient-onboarding-post';
import { PatientOnboardingPost$Params } from '../fn/patient-onboarding/patient-onboarding-post';
import { patientOnboardingSearchIdNumberGet } from '../fn/patient-onboarding/patient-onboarding-search-id-number-get';
import { PatientOnboardingSearchIdNumberGet$Params } from '../fn/patient-onboarding/patient-onboarding-search-id-number-get';

@Injectable({ providedIn: 'root' })
export class CalendarPatientOnboardingService extends CalendarBaseService {
  constructor(config: CalendarApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `patientOnboardingIdGet()` */
  static readonly PatientOnboardingIdGetPath = '/PatientOnboarding/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientOnboardingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingIdGet$Response(params: PatientOnboardingIdGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<PatientOnboardingModel>> {
    return patientOnboardingIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientOnboardingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingIdGet(params: PatientOnboardingIdGet$Params, context?: HttpContext): Observable<PatientOnboardingModel> {
    return this.patientOnboardingIdGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<PatientOnboardingModel>): PatientOnboardingModel => r.body)
    );
  }

  /** Path part for operation `patientOnboardingSearchIdNumberGet()` */
  static readonly PatientOnboardingSearchIdNumberGetPath = '/PatientOnboarding/search/{idNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientOnboardingSearchIdNumberGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingSearchIdNumberGet$Response(params: PatientOnboardingSearchIdNumberGet$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<PatientOnboardingModel>> {
    return patientOnboardingSearchIdNumberGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientOnboardingSearchIdNumberGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patientOnboardingSearchIdNumberGet(params: PatientOnboardingSearchIdNumberGet$Params, context?: HttpContext): Observable<PatientOnboardingModel> {
    return this.patientOnboardingSearchIdNumberGet$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<PatientOnboardingModel>): PatientOnboardingModel => r.body)
    );
  }

  /** Path part for operation `patientOnboardingPost()` */
  static readonly PatientOnboardingPostPath = '/PatientOnboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patientOnboardingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientOnboardingPost$Response(params?: PatientOnboardingPost$Params, context?: HttpContext): Observable<CalendarStrictHttpResponse<string>> {
    return patientOnboardingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patientOnboardingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patientOnboardingPost(params?: PatientOnboardingPost$Params, context?: HttpContext): Observable<string> {
    return this.patientOnboardingPost$Response(params, context).pipe(
      map((r: CalendarStrictHttpResponse<string>): string => r.body)
    );
  }

}
